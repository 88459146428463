import { Fragment } from "react"
import { Check, X } from 'react-feather'
import { CustomInput } from "reactstrap"

const Label = () => (
  <Fragment>
    <span className="switch-icon-left">
      <Check size={14} />
    </span>
    <span className="switch-icon-right">
      <X size={14} />
    </span>
  </Fragment>
)

export const Switch = ({ key, checked, onClick = () => {}, ...props }) => {
  return (
    <CustomInput
      type="switch"
      label={<Label />}
      id="icon-primary"
      name="icon-primary"
      key={key}
      inline
      checked={checked}
      onChange={onClick}
      {...props}
    />
  )
}
