import { useConfigs } from '../../../utility/hooks/useConfigs'
import styles from '../styles.module.scss'

export function MainData({
  onConfig = () => {},
  onAccept = () => {},
  onReject = () => {}
}) {
  const { cmsOrigin } = useConfigs()
  const isLiberta = cmsOrigin === 'liberta'
  return (
    <>
      <div>
        <p className={isLiberta ? styles.titleLiberta : styles.title}>Esse site usa cookies</p>
      </div>
      <div>
        <p className={styles.text}>
          Clicando em "Aceitar", você aceita nossa política de cookies com
          objetivo de melhorar sua navegação. Conheça{' '}
          {isLiberta ? (
            <a
              href="#"
              onClick={() => {
                onConfig(isLiberta)
              }}
            >
              nossa política
            </a>
          ) : (
            <a
              href={`${process.env.REACT_APP_SITE_URL}/politica-de-privacidade`}
              target={'_blank'}
              rel="noreferrer"
            >
              nossa política
            </a>
          )}{' '}
          e{' '}
          <span
            onClick={() => {
              onConfig()
            }}
          >
            configure seus cookies
          </span>
          .
        </p>
      </div>
      <div className={styles.buttonContainer}>
        <span className={styles.buttonReject} onClick={onReject}>
          Rejeitar
        </span>
        <span className={isLiberta ? styles.buttonAcceptLiberta : styles.buttonAccept} onClick={() => onAccept()}>
          Aceitar
        </span>
      </div>
    </>
  )
}
