import api from './api'

export default {
  get: async (params) => {
    const response = await api
      .get('/api/list-privacy-policy', {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  show: async (privacyPolicyId) => {
    const response = await api
      .get(`/api/find-privacy-policy/${privacyPolicyId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  create: async (data) => {
    const response = await api
      .post('/api/create-privacy-policy', data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  update: async (privacyPolicyId, data) => {
    const response = await api
      .put(`/api/update-privacy-policy/${privacyPolicyId}`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  toggle: async (privacyPolicyId) => {
    const response = await api
      .put(`/api/privacy-policy-toggle-active/${privacyPolicyId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  delete: async (privacyPolicyId) => {
    const response = await api
      .delete(`/api/delete-privacy-policy/${privacyPolicyId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getPrivacyPolicy: async () => {
    const response = await api
      .get('/api/find-liberta-privacy-policy')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  }
}
