import { createContext, useContext, useState } from 'react'


const TrailContext = createContext({})
function TrailProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false)
  const [productId, setProductId] = useState(null)
  const [show, setShow] = useState(false)

  return (
    <TrailContext.Provider
      value={{
        isOpen,
        setIsOpen,
        productId,
        setProductId,
        show,
        setShow
      }}
    >
      {children}
    </TrailContext.Provider>
  )
}
function useTrail() {
  return useContext(TrailContext)
}

export { TrailProvider, useTrail }
