import api from './api'

export default {
  get: async (params) => {
    const response = await api
      .get('/api/list-customer', {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getAll: async (params) => {
    const response = await api
      .get('/api/list-user', {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getOrders: async (customerId, params) => {
    const response = await api
      .get(`/api/list-customer-orders/${customerId}`, {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getProducts: async (customerId, params) => {
    const response = await api
      .get(`/api/list-customer-products/${customerId}`, {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getSignatures: async (customerId, params) => {
    const response = await api
      .get(`/api/list-customer-products/${customerId}`, {
        params: { ...params, type: 3 }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getRefunds: async (customerId, params) => {
    const response = await api
      .get(`/api/list-customer-refunds/${customerId}`, {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getReports: async (customerId, params) => {
    const response = await api
      .get(`/api/list-customer-courtesy-report/${customerId}`, {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  show: async (customerId) => {
    const response = await api
      .get(`/api/find-customer/${customerId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  showDetails: async (customerId) => {
    const response = await api
      .get(`/api/customer-details/${customerId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  create: async (data) => {
    const response = await api
      .post('/api/create-customer', data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  update: async (customerId, data) => {
    const response = await api
      .put(`/api/update-customer/${customerId}`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  delete: async (customerId) => {
    const response = await api
      .delete(`/api/delete-customer/${customerId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  updateContactData: async (customerId, data) => {
    const response = await api
      .put(`/api/update-customer-contact-data/${customerId}`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  createCourtesy: async (data) => {
    const response = await api
      .post(`/api/create-user-courtesy`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  createCourtesyReports: async (data) => {
    const response = await api
      .post(`/api/create-user-courtesy-report`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  loginAsCustomer: async ({ idClient, returnMain }) => {
    const response = await api
      .get(`/api/login-as-customer/${idClient}`, {
        params: {
          returnMain
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  loginBackMainUser: async ({ idClient, returnMain, token }) => {
    const response = await api
      .get(`/api/login-as-customer/${idClient}`, {
        params: {
          returnMain,
          notSendToken: true
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getAddressByCep: async (zip_code) => {
    const response = await api
      .get(`/api/consult-zip-code`, { params: { zip_code } })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
