import {useCallback, useEffect, useState} from 'react'
import { toast } from 'react-toastify'
import {
  Modal as ModalReact,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner
} from 'reactstrap'
import policy from '../../../services/policy'
import styles from '../styles.module.scss'


export const Modal = ({ isOpen, handleToggle }) => {
  const [description, setDescription] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const getPolity = useCallback(async () => {
    if (description.length > 0) {
      return
    }
    const response = await policy.getPrivacyPolicy()
    if (response.data && response.status === 200) {
      setIsLoading(false)
      setDescription(response.data.description)
      return
    }
    setIsLoading(false)
    return toast.error('Erro ao buscar informações')
  }, [description])

  useEffect(() => {
    if (!isOpen) {
      return
    }
    getPolity()
  }, [isOpen])

  return (
    <>
      <ModalReact
        isOpen={isOpen}
        modalClassName="modal-success"
        className="modal-dialog-centered"
        toggle={handleToggle}
      >
        <ModalHeader toggle={handleToggle}>Politica de privacidade</ModalHeader>
        <ModalBody>
          {isLoading ? (
            <Spinner
              color="primary-default"
              style={{ width: '3rem', height: '3rem' }}
            />
          ) : (
            <div className={styles.containerModal} dangerouslySetInnerHTML={{ __html: description }}></div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleToggle}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalReact>
    </>
  )
}
