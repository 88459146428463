import {createContext} from "react"

import {useFloating} from "@hooks/useFloating"

const FloatingContext = createContext()

const FloatingProvider = ({children}) => {
    const {
        content,
        setContent,
        isOpen,
        setIsOpen,
        videoCurrentTime,
        setVideoCurrentTime,
        contentUrl,
        setContentUrl,
        broadcastDataFloating,
        setBroadcastDataFloating,
        isMenuCollapsedContext,
        setIsMenuCollapsedContext

    } = useFloating()

    return (
        <FloatingContext.Provider value={{
            content,
            setContent,
            isOpen,
            setIsOpen,
            videoCurrentTime,
            setVideoCurrentTime,
            contentUrl,
            setContentUrl,
            broadcastDataFloating,
            setBroadcastDataFloating,
            isMenuCollapsedContext,
            setIsMenuCollapsedContext
        }}>
            {children}
        </FloatingContext.Provider>
    )
}

export {FloatingContext, FloatingProvider}
