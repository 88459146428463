// ** Logo
import config from '../../../configs/config'
import { storageItem } from "@utils"

const SpinnerComponent = () => {
  const cmsOrigin = storageItem('get', '@L&S::AppOrigin')
  return (
    <div className='fallback-spinner vh-100' style={{
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center'
    }}>
      {config && cmsOrigin && config[cmsOrigin]?.images?.logo?.src && (
        <img className='fallback-logo' src={config[cmsOrigin]?.images?.logo?.src} alt='logo' />
      )}
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
