import { createContext, useContext, useEffect, useState } from 'react'

const ModalContext = createContext({})
function ModalProvider({ children }) {
  const [broadcastFeedSubscribeModal, setBroadcastFeedSubscribeModal] =
    useState(false)
  const [broadcastSignatureName, setBroadcastSignatureName] = useState('')
  const [broadcastDontBeClosed, setBroadcastDontBeClosed] = useState(false)
  const [broadcastRedirectAfterClosed, setBroadcastRedirectAfterClosed] =
    useState(null)
  const [closeListener, setCloseListener] = useState(null)
  const [nameSignatureType, setNameSignatureType] = useState('')
  const [modalButtonText, setModalButtonText] = useState('Quero assinar')
  //Estado para controlar modal que abre em politicas de privacidade
  const [isOpenModalPolicy, setIsOpenModalPolicy] = useState(false)
  const [titleModal, setTitleModal] = useState('Assinatura necessária:')

  const [availableInStore, setAvailableInStore] = useState(false)
  const [sendToSpecificRoute, setSendToSpecificRoute] = useState(null)

  useEffect(() => {
    //sempre que o modal fechar, reseta o texto do botão e a rota
    if (!broadcastFeedSubscribeModal) {
      setModalButtonText('Quero assinar')
      setSendToSpecificRoute(null)
    }
  }, [broadcastFeedSubscribeModal])

  return (
    <ModalContext.Provider
      value={{
        broadcastFeedSubscribeModal,
        setBroadcastFeedSubscribeModal,
        broadcastSignatureName,
        setBroadcastSignatureName,
        broadcastDontBeClosed,
        setBroadcastDontBeClosed,
        broadcastRedirectAfterClosed,
        setBroadcastRedirectAfterClosed,
        closeListener,
        setCloseListener,
        nameSignatureType,
        setNameSignatureType,
        isOpenModalPolicy,
        setIsOpenModalPolicy,
        availableInStore,
        setAvailableInStore,
        modalButtonText,
        setModalButtonText,
        sendToSpecificRoute,
        setSendToSpecificRoute,
        titleModal,
        setTitleModal
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
function useModal() {
  return useContext(ModalContext)
}

export { ModalProvider, useModal }
