import { useContext, createContext, useState, useEffect } from 'react'
import { ModalNps } from '../../components/ModalNps'
import nps from '../../services/nps'

const NPSContext = createContext({})

function NPSProvider({ children }) {
  const [openNps, setOpenNps] = useState(false)
  const [idNps, setIdNps] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])

  const handleClose = () => {
    setIdNps(null)
    setOpenNps(false)
  }

  const getQuestion = async (id) => {
    setIsLoading(true)
    const response = await nps.getListQuestionNpsUser(id)
    if (response.status === 200) {
      if (response.data.length < 1) {
        setData([])
        setOpenNps(false)
      } else {
        setData(response.data)
        setOpenNps(true)
      }
    }
    setIsLoading(false)
  }
  const handleOpen = async (id) => {
    //Quando a categoria for nula o end-point ira retornar todas as perguntas em aberto
    if (!!id || id === null) {
      await getQuestion(id)
    } else {
      if (data.length > 0) {
        setOpenNps(true)
      }
    }
  }

  const getAllQuestion = async () => {
    const response = await nps.getListAllQuestionNps()
    if (response?.data?.length > 0) {
      setData(response.data)
      setOpenNps(true)
    }
  }

  useEffect(() => {
    getAllQuestion()
  }, [])

  useEffect(() => {
    if (openNps && data.length === 0) {
      setOpenNps(false)
    }
  }, [openNps])

  return (
    <NPSContext.Provider
      value={{ openNps, handleOpen, handleClose, idNps, data, isLoading }}
    >
      <ModalNps />
      {children}
    </NPSContext.Provider>
  )
}

function useNPS() {
  return useContext(NPSContext)
}

export { NPSProvider, useNPS }
