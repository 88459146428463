import { createContext, useContext, useState } from 'react'
import { getDataUserAddress, getUserData } from '../Utils'

const FormUserRequiredContext = createContext({})

const FormUserRequiredProvider = ({ children }) => {
  const [modalIsOpen, setModelIsOpen] = useState(false)
  const [user, setUser] = useState(null)
  const [userAux, setUserAux] = useState(() => {
    const userValue = sessionStorage.getItem('@LP::InfoUserData')
    if (userValue) {
      return JSON.parse(userValue)
    }
    return null
  })
  const infoNeed = (obj) => {
    if (obj) {
      return {
        id: obj?.id,
        email: obj?.email,
        name: obj?.name,
        nickname: obj?.nickname,
        identifier: obj?.identifier,
        gender: obj?.gender,
        birth_date: obj?.birth_date,
        ocupation: obj?.ocupation
      }
    }
    return null
  }
  const transformArrays = (obj) => {
    return Object.keys(obj).map((key) => {
      if (key === 'complement' || key === 'id') return ''
      return obj[key]
    })
  }

  const verifyRequiredUserMainData = async () => {
    if (userAux) {
      setUser(userAux)
      return null
    }
    const userData = infoNeed(await getUserData())
    if (!userData) return null
    const addressUser = await getDataUserAddress(userData?.id)

    const allDataUser = {
      ...userData,
      ...addressUser
    }
    setUser(allDataUser)
    const arrayUser = transformArrays(allDataUser)

    if (arrayUser.includes(null)) {
      return allDataUser
    } else {
      return null
    }
  }

  return (
    <FormUserRequiredContext.Provider
      value={{
        user,
        userAux,
        modalIsOpen,
        setModelIsOpen,
        verifyRequiredUserMainData,
        setUserAux
      }}
    >
      {children}
    </FormUserRequiredContext.Provider>
  )
}
const useFormUserRequired = () => {
  return useContext(FormUserRequiredContext)
}

export { useFormUserRequired, FormUserRequiredProvider }
