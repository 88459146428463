import styles from "../styles.module.scss"
import AppCollapse from '@components/app-collapse'
import {useEffect, useMemo, useState} from "react"
import { Switch } from '@src/components/Switch'
import {BiLockOpen} from "react-icons/bi"
import { useConfigs } from "../../../utility/hooks/useConfigs"

export function ConfigData({onAccept = () => {}, onReject = () => {}}) {
  const [cookiesSettings, setCookiesSettings] = useState({
    required: true,
    statistics: false,
    preferences: false,
    marketing: false
  })
  const {cmsOrigin} = useConfigs()
  const isLiberta = cmsOrigin === 'liberta'

  useEffect(() => {
    console.log({cookiesSettings})
  }, [cookiesSettings])
  const requiredData = useMemo(() => ([
    {
      title: (
        <div className={styles.accordionHeader}>
          <p>Necessários</p>
          <div className={`${styles.switchWrapper} ${styles.switchDisabled} d-flex`}>
            <BiLockOpen />
            <Switch disabled={true} id={"requiredData"} checked={cookiesSettings.required} key={0} onClick={() => setCookiesSettings(prev => {
              return {
                ...prev,
                required: !prev.required
              }
            })} />
          </div>
        </div>
      ),
      content: (
        <p>São os cookies necessários para o funcionamento do nosso sistema. Incluem, por exemplo, cookies que permitem aos visitantes navegar em áreas seguras do site. Não é possível desativar cookies necessários, porém rejeitando a gravação ao final da navegação todos os dados de cookies serão eliminados.</p>
      )
    }
  ]), [cookiesSettings])
  const statisticsData = useMemo(() => ([
    {
      title: (
        <div className={styles.accordionHeader}>
          <p>Estatísticas</p>
          <div className={`${styles.switchWrapper} d-flex`}>
            <Switch id={"statisticsData"} checked={cookiesSettings.statistics} key={0} onClick={() => setCookiesSettings(prev => {
              return {
                ...prev,
                statistics: !prev.statistics
              }
            })} />
          </div>
        </div>
      ),
      content: (
        <p>Permitem reconhecer, contar o número de visitantes e ver como estes se movem pelo nosso website quando o utilizam. Isso ajuda a melhorar o funcionamento do nosso website, por exemplo, garantindo aos usuários encontrar facilmente o que estão procurando.</p>
      )
    }
  ]), [cookiesSettings])
  const preferencesData = useMemo(() => ([
    {
      title: (
        <div className={styles.accordionHeader}>
          <p>Preferências</p>
          <div className={`${styles.switchWrapper} d-flex`}>
            <Switch id={"preferencesData"} checked={cookiesSettings.preferences} key={0} onClick={() => setCookiesSettings(prev => {
              return {
                ...prev,
                preferences: !prev.preferences
              }
            })} />
          </div>
        </div>
      ),
      content: (
        <p>São utilizados para reconhecer os usuários quando estes regressam ao nosso site. Isso permite personalizar nosso conteúdo para os usuários ao recordar as suas preferências, como por exemplo, a sua escolha de idioma ou região.</p>
      )
    }
  ]), [cookiesSettings])
  const marketingData = useMemo(() => ([
    {
      title: (
        <div className={styles.accordionHeader}>
          <p>Marketing</p>
          <div className={`${styles.switchWrapper} d-flex`}>
            <Switch id={"marketingData"} checked={cookiesSettings.marketing} key={0} onClick={() => setCookiesSettings(prev => {
              return {
                ...prev,
                marketing: !prev.marketing
              }
            })} />
          </div>
        </div>
      ),
      content: (
        <p>Os cookies de marketing registram de forma anônima a navegação dos usuários do nosso site. Usaremos essa informação para direcionar nossas campanhas de publicidade a pessoas com maior probabilidade de se interessarem no que temos a oferecer.  </p>
      )
    }
  ]), [cookiesSettings])
  return (
    <>
      <div>
        <p className={isLiberta ? styles.titleLiberta : styles.title}>Quais Cookies você deseja aceitar?</p>
      </div>
      <div className={styles.accordionContainer}>
        <AppCollapse data={requiredData} className={`${styles.accordion} cookies-app-collapse`} />
        <AppCollapse data={statisticsData} className={`${styles.accordion} cookies-app-collapse`} />
        <AppCollapse data={preferencesData} className={`${styles.accordion} cookies-app-collapse`} />
        <AppCollapse data={marketingData} className={`${styles.accordion} cookies-app-collapse`} />
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.footerInfoContainer}>
          <BiLockOpen />
          <span>Cookie obrigatório</span>
        </div>
        <div className={styles.buttonContainer}>
          <span className={styles.buttonReject} onClick={onReject}>Rejeitar</span>
          <span className={isLiberta ? styles.buttonAcceptLiberta : styles.buttonAccept} onClick={() => onAccept(cookiesSettings)}>Aceitar</span>
        </div>
      </div>
    </>
  )
}
