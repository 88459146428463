import { MainData } from './steps/MainData'
import styles from './styles.module.scss'
import { ConfigData } from './steps/ConfigData'
import { useEffect, useRef, useState } from 'react'
import { storageItem } from '../../utility/Utils'
import Cookies from 'universal-cookie'
import { Modal } from './components/Modal'
import { useModal } from '../../utility/hooks/useModal'

export function CookiesSettings() {
  const [currentStep, setCurrentStep] = useState("main")
  const [isShow, setIsShow] = useState(false)
  const { isOpenModalPolicy, setIsOpenModalPolicy } = useModal()
  const onConfig = (isLiberta = false) => {
    if (isLiberta) {
      setIsOpenModalPolicy(true)
      return
    }
    setCurrentStep('config')
  }

  const onAccept = (cookiesSettings = undefined) => {
    const currentDate = new Date()
    const formattedDate = new Date(currentDate.getTime() + (86400000 * 365))
    if (!cookiesSettings) {
      cookiesSettings = {
        required: true,
        statistics: true,
        preferences: true,
        marketing: true
      }
    }
    storageItem(
      'set',
      '@L&S::CookiesSettings',
      cookiesSettings,
      true,
      formattedDate
    )
    setIsShow(false)
  }
  const onReject = () => {
    const currentDate = new Date()
    const formattedDate = new Date(currentDate.getTime() + (86400000 * 365))
    const cookiesSettings = {
      required: true,
      statistics: false,
      preferences: false,
      marketing: false
    }
    storageItem(
      'set',
      '@L&S::CookiesSettings',
      cookiesSettings,
      true,
      formattedDate
    )
    setIsShow(false)
  }

  useEffect(() => {
    const cookie = new Cookies()
    const cookieKeys = Object.keys(cookie.getAll())
    if (!cookieKeys.find((item) => item === '@L&S::CookiesSettings')) {
      setIsShow(true)
    }
  }, [])

  const onCloseTabListenerCalled = useRef(false)
  const onCloseTab = () => {
    const cookie = new Cookies()
    const cookieKeys = Object.keys(cookie.getAll())

    if (cookieKeys.find((item) => item === '@L&S::CookiesSettings')) {
      const cookiesSettings = storageItem(
        'get',
        '@L&S::CookiesSettings',
        undefined,
        true
      )
      if (
        cookiesSettings.statistics ||
        cookiesSettings.preferences ||
        cookiesSettings.marketing
      ) {
        return
      }
    }

    for (const key of cookieKeys) {
      cookie.remove(key)
    }
  }

  useEffect(() => {
    if (window) {
      if (onCloseTabListenerCalled.current) {
        return
      }
      onCloseTabListenerCalled.current = true
      window.addEventListener('beforeunload', onCloseTab)
      return () => window.removeEventListener('beforeunload', onCloseTab)
    }
  }, [window])

  const handleToggle = () => {
    setIsOpenModalPolicy(false)
  }

  if (window.location.pathname === '/carrinho') {
    return <></>
  }

  return (
    <>
      {
        isShow ? (
          <div className={styles.container}>
            {currentStep === 'main' && (
              <MainData
                onConfig={onConfig}
                onAccept={onAccept}
                onReject={onReject}
              />
            )}
            {currentStep === 'config' && (
              <ConfigData onAccept={onAccept} onReject={onReject} />
            )}
          </div>
        ) : (
          <></>
        )
      }
      <Modal isOpen={isOpenModalPolicy} handleToggle={handleToggle} isShow={isShow} />
    </>
  )
}
