import { createContext, useContext, useEffect, useState } from 'react'
import { isUserLoggedIn } from '../Utils'

const SessionContext = createContext({})
function SessionProvider({ children }) {
  const [isLogged, setIsLogged] = useState(!!isUserLoggedIn())
  const [isFileDownloadAfterLogin, setIsFileDownloadAfterLogin] =
    useState(false)

  useEffect(() => {
    setIsLogged(!!isUserLoggedIn())
  }, [])

  return (
    <SessionContext.Provider
      value={{
        isLogged,
        setIsLogged,
        isFileDownloadAfterLogin,
        setIsFileDownloadAfterLogin
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}
function useSession() {
  return useContext(SessionContext)
}

export { SessionProvider, useSession }
