import { useContext, createContext, useState } from 'react'

const ModeThemeContext = createContext({})
import { useSkin } from '@hooks/useSkin'

const ModeThemeProvider = ({ children }) => {
  const [sckin] = useSkin()
  const [modeTheme, setModeTheme] = useState(sckin ? sckin : "dark")
  return (
    <ModeThemeContext.Provider value={{ modeTheme, setModeTheme }}>
      {children}
    </ModeThemeContext.Provider>
  )
}

const useModeTheme = () => useContext(ModeThemeContext)

export { useModeTheme, ModeThemeProvider }
