import api from './api'

export default {
  login: async (data) => {
    const response = await api
      .post('/api/login', data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  signUp: async (data, headers) => {
    const response = await api
      .post(
        '/api/ls-brazil-create-user',
        data,
        headers && {
          headers
        }
      )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  get: async (data) => {
    const response = await api
      .get(`/api/user`, data && { params: data })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  checkEmail: async (email) => {
    const response = await api
      .get(`/api/check-email/`, {
        params: { email }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  forgotPassword: async (data) => {
    const response = await api
      .post('/api/forgot-password', data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  resetPassword: async (data) => {
    const response = await api
      .post('/api/reset-password', data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  sendAuthCode: async (data) => {
    const response = await api
      .post('api/ls-brazil-send-auth-code', data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getAdDomains: async () => {
    const response = await api
      .get('api/login-advisor')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  sendCodeAd: async (data) => {
    const response = await api
      .post('api/login-advisor', data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  }
}
