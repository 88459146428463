import api from './api'

export default {
  get: async (params) => {
    const response = await api
      .get('/api/list-user', {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getSignatureList: async (userId, params) => {
    const response = await api
      .get(`/api/list-user-signature/${userId}`, {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  show: async (userId) => {
    const response = await api
      .get(`/api/find-user/${userId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  create: async (data) => {
    const response = await api
      .post('/api/create-user', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  update: async (userId, data) => {
    const response = await api
      .post(`/api/update-user/${userId}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  delete: async (userId) => {
    const response = await api
      .delete(`/api/delete-user/${userId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  changePassword: async (userId, new_password) => {
    const response = await api
      .put(`/api/user-new-password/${userId}`, {
        new_password
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  changeProfilePassword: async (userId, data) => {
    const response = await api
      .put(`/api/user-change-password/${userId}`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  showProfile: async (userId) => {
    const response = await api
      .get(`/api/find-user-profile/${userId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  removeUserProfileImage: async (userId) => {
    const response = await api
      .put(`/api/remove-user-profile-image/${userId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  updateUserProfileImage: async (userId, data) => {
    const response = await api
      .post(`/api/update-user-profile-image/${userId}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  updateUserProfile: async (userId, data) => {
    const response = await api
      .post(`/api/update-user-profile/${userId}`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  updateUserProfileInterest: async (userId, data) => {
    const response = await api
      .put(`/api/update-user-profile-interest/${userId}`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  createAddress: async (data) => {
    const response = await api
      .post(`/api/create-user-address`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  updateAddress: async (id, data) => {
    const response = await api
      .put(`/api/update-user-address/${id}`, data)
      .then(({ ...response }) => response)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  deleteAddress: async (id) => {
    const response = await api
      .delete(`/api/delete-user-address/${id}`)
      .then(({ ...response }) => response)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  selectAddresses: async (data) => {
    const response = await api
      .get(`/api/select-user-addresses`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  selectAddress: async (id) => {
    const response = await api
      .get(`/api/find-user-address-details/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  mySignatures: async (id, params) => {
    const response = await api
      .get(`/api/list-user-signature/${id}`, {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  userCourses: async (id, params) => {
    const response = await api
      .get(`/api/list-courses-by-user/${id}`, {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  saveCourses: async (data) => {
    const response = await api
      .post('/api/register-user-courses', data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  registerTrial: async (data, headers) => {
    const response = await api
      .post(
        `/api/user-trial-access/`,
        data,
        headers && {
          headers: headers.utm_campaign &&
            headers.utm_medium &&
            headers.utm_source && {
              'utm-source': headers.utm_source,
              'utm-medium': headers.utm_medium,
              'utm-campaign': headers.utm_campaign,
              ...(headers?.utm_content && {
                'utm-content': headers.utm_content
              }),
              ...(headers?.utm_term && {
                'utm-term': headers.utm_term
              }),
              ...(headers?.utm_influenciador && {
                'utm-influenciador': headers.utm_influenciador
              }),
              ...(headers?.utm_objetivo && {
                'utm-objetivo': headers.utm_objetivo
              })
            }
        }
      )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  sendZenviaToken: async (phone_number) => {
    const response = await api
      .post(`/api/send-zenvia-token`, { phone_number })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  checkXpCode: async () => {
    const response = await api
      .get(`/api/check-xpcode`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getDomains: async () => {
    const response = await api
      .get('/api/get-unrestricted-domains')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  updateUserProfileModalRequired: async (id, data) => {
    const response = await api
      .post(`/api/update-user-data/${id}`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  checkActive: async (email) => {
    const response = await api
      .get(`api/lp-check-user/${email}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getSelectCountry: async () => {
    const response = await api
      .get(`api/select-country`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getSelectState: async (id) => {
    const response = await api
      .get(`api/select-state/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getSelectsTrails: async () => {
    const response = await api
      .get(`api/list-trial/`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getUserTrails: async () => {
    const response = await api
      .get(`api/list-user-trial/`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getKnowMore: async (id) => {
    const response = await api
      .get(`api/liberta-find-signature-type/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
