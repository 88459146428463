import { Box, Collapse, IconButton, Typography } from '@mui/material'
import { useNPS } from '../../utility/hooks/useNPS'
import { Button, Modal, ModalBody, Spinner } from 'reactstrap'
import { PrettoSlider } from './components/Pretto'
import { Svg } from '@src/components/Svg'
import styles from './styles.module.scss'
import img from '../../assets/images/avatars/img-modal-nps.png'
import nps from '../../services/nps'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { IoMdClose } from 'react-icons/io'
import { useEffect } from 'react'

export const ModalNps = () => {
  const { handleClose, openNps, idNps, data, isLoading } = useNPS()

  const defaultValues = {}

  for (const obj of data) {
    defaultValues[obj.nps_question_users_id] = undefined
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm({
    defaultValues
  })

  useEffect(() => {
    if (openNps) {
      reset({})
    }
  }, [openNps])

  const marks = Array.from({ length: 10 }, (_, index) => ({
    value: index + 1,
    label: (
      <Typography fontSize={'12px'} color={'#5B5B5B'}>
        {index + 1}
      </Typography>
    )
  }))

  function valuetext(value) {
    return `${value}`
  }
  const onSubmit = async (data) => {
    const sendData = Object.keys(data)
      .map((item) => ({
        answer: data[item],
        id: item
      }))
      .filter((item) => !!item.answer)
    if (sendData.length === 0) {
      return toast('Responda ao menos uma pergunta.')
    }
    const response = await nps.createNpsAnswer(idNps, sendData)
    if (response.status === 200) {
      toast.success('Pesquisa salva com sucesso')
      handleClose()
      return
    }
    toast.success('Erro ao salvar.')
  }
  return (
    <>
      <Modal
        isOpen={openNps}
        style={{ maxWidth: '700px' }}
        centered
        modalTransition={{ timeout: 300 }}
        backdropTransition={{ timeout: 650 }}
      >
        <ModalBody className="p-0">
          <Box
            display={'flex'}
            justifyContent={'center'}
            overflow={'hidden'}
            alignItems="flex-start"
          >
            <Box maxWidth={'250px'}>
              <img
                style={{
                  maxWidth: '250px',
                  height: 'auto',
                  maxHeight: '100vh'
                }}
                src={img}
              />
            </Box>
            <Box
              alignItems={'flex-start'}
              width={'100%'}
              display={'flex'}
              flexDirection={'column'}
              flex={1}
              pl={3}
              pr={0}
              maxWidth={'470px'}
              pt={2}
              component={'form'}
              onSubmit={handleSubmit(onSubmit)}
            >
              <IconButton
                tabIndex={-1}
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  fontSize: '1.8rem',
                  color: '#1ED760'
                }}
                onClick={handleClose}
              >
                <IoMdClose />
              </IconButton>

              <Typography
                id="modal-modal-title"
                variant="subtitle2"
                component="span"
              >
                NPS - Avalie a Plataforma LibertaPlay
              </Typography>
              <Box
                width={'70px'}
                height={'3px'}
                borderRadius={1}
                bgcolor={'#1ED760'}
                mb={4}
              ></Box>
              <Collapse
                in={isLoading}
                sx={{
                  width: '100%',
                  pt: { ...(isLoading ? 20 : 0) }
                }}
              >
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Spinner
                    color="primary-default"
                    style={{ width: '3rem', height: '3rem' }}
                  />
                </Box>
              </Collapse>
              <Collapse in={!isLoading}>
                <Box
                  sx={{
                    overflowY: data.length === 1 ? 'auto' : 'scroll',
                    overflowX: 'hidden',
                    maxHeight: '324px'
                  }}
                  pr={2}
                >
                  {data.map((item, index) => (
                    <div key={index}>
                      <Box display={'flex'}>
                        <Box mr={1}>
                          <Svg name="arrow-nps" />
                        </Box>
                        <Box flex={1} pr={!!item.open_question ? 2.3 : 0}>
                          <Typography
                            id="modal-modal-body-question"
                            variant="subtitle1"
                            component="p"
                            fontWeight={'bold'}
                          >
                            {item.question}
                          </Typography>
                          {!!item.open_question ? (
                            <>
                              <Typography
                                id="modal-modal-warning"
                                variant="caption"
                                component="span"
                                color={'#636060'}
                              >
                                Digite abaixo até 400 carácteres
                              </Typography>
                              <Controller
                                control={control}
                                name={`${item.nps_question_users_id}`}
                                rules={{ maxLength: 400 }}
                                render={({ field }) => (
                                  <textarea
                                    {...field}
                                    onInvalid={true}
                                    className={`${styles.textArea} ${
                                      !!errors[item.nps_question_users_id]
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    disabled={isSubmitting}
                                  />
                                )}
                              />
                            </>
                          ) : (
                            <>
                              <Typography
                                id="modal-modal-warning"
                                variant="caption"
                                component="span"
                                color={'#636060'}
                              >
                                Selecione uma nota entre 1 e 10
                              </Typography>
                              <Box width={'100%'} mt={1}>
                                <Controller
                                  control={control}
                                  name={`${item.nps_question_users_id}`}
                                  render={({ field }) => (
                                    <PrettoSlider
                                      {...field}
                                      aria-label="Always visible"
                                      getAriaValueText={valuetext}
                                      step={1}
                                      marks={marks}
                                      valueLabelDisplay="auto"
                                      min={1}
                                      max={10}
                                      disabled={isSubmitting}
                                    />
                                  )}
                                />
                              </Box>
                            </>
                          )}
                        </Box>
                      </Box>
                      {data.length !== index + 1 && (
                        <Box
                          width={'100%'}
                          height={'1px'}
                          bgcolor={'#4B4B4B'}
                          my={3}
                        ></Box>
                      )}
                    </div>
                  ))}
                </Box>
                <Box
                  display={'flex'}
                  justifyContent={'flex-end'}
                  pt={1.5}
                  pr={2}
                  pb={1}
                  position={'absolute'}
                  right={0}
                  bottom={0}
                >
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary-default"
                  >
                    Enviar
                  </Button>
                </Box>
              </Collapse>
            </Box>
          </Box>
        </ModalBody>
      </Modal>
    </>
  )
}
