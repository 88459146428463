
// You can customize the template with the help of this file

export const getOrigin = () => {
  let origin = 'brasil'
  if (window.location.origin === process.env.REACT_APP_GLOBAL_URL) {
    origin = 'global'
  } else if (window.location.origin === process.env.REACT_APP_LIBERTA_URL || window.location.origin === 'http://127.0.0.1:3005') {
    origin = 'liberta'
  }
  return origin
}

const isLiberta = getOrigin() === 'liberta'
//Template config options
const themeConfig = {
  app: {
    appName: 'L&S',
    appLogoImage: require('@src/assets/images/logo/logo.svg').default,
    appLogoImageWhite: require('@src/assets/images/logo/logo_white.svg').default
  },
  layout: {
    isRTL: false,
    skin: isLiberta ? 'dark' : 'light', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: true
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  }
}

export default themeConfig
