import api from './api'

export default {
  company: async () => {
    const response = await api
      .get('/api/select-company')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  cmsContentType: async () => {
    const response = await api
      .get('/api/select-cms-content-type')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  cmsSelectCourse: async () => {
    const response = await api
      .get('/api/select-course')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  cmsSelectVideo: async () => {
    const response = await api
      .get('/api/select-video')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  category: async () => {
    const response = await api
      .get('/api/select-category')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  courseLevel: async () => {
    const response = await api
      .get('/api/select-course-level')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  instructor: async () => {
    const response = await api
      .get('/api/select-instructor')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  accessPeriod: async () => {
    const response = await api
      .get('/api/select-access-period')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  courseType: async () => {
    const response = await api
      .get('/api/select-course-type')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  courseCity: async (params) => {
    const response = await api
      .get('/api/select-course-city', {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  accessProfile: async () => {
    const response = await api
      .get('/api/select-base-profile')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  status: async (params) => {
    const response = await api
      .get('/api/select-recommendation-status', params && { params })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  allStatus: async (params) => {
    const response = await api
      .get('/api/select-all-recommendation-status', params && { params })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  courseUf: async () => {
    const response = await api
      .get('/api/select-course-uf')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  uf: async () => {
    const response = await api
      .get('/api/select-uf')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  city: async (ufId) => {
    const response = await api
      .get(`/api/select-cities/${ufId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  typeScheduleGrid: async () => {
    const response = await api
      .get('/api/select-type-schedule-grid')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  gender: async () => {
    const response = await api
      .get('/api/select-gender')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  stopConduction: async () => {
    const response = await api
      .get('/api/select-stop-conductions')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  targetConduction: async () => {
    const response = await api
      .get('/api/select-target-conductions')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  positionsSizes: async (params) => {
    const response = await api
      .get('/api/select-position-sizes', params && { params })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  directions: async () => {
    const response = await api
      .get('/api/select-operation-type')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  financialAsset: async () => {
    const response = await api
      .get('/api/select-financial-assets')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  frequency: async () => {
    const response = await api
      .get('/api/select-frequencies')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  methods: async () => {
    const response = await api
      .get('/api/select-trade-methods')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  analystCallFast: async () => {
    const response = await api
      .get('/api/select-analyst-for-recommendation')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  analystMethods: async (analystId) => {
    const response = await api
      .get(`/api/select-analyst-trade-methods/${analystId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  setupsMage: async (assetId) => {
    const response = await api
      .get(`/api/select-setups-mago/${assetId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  financialAssetMage: async () => {
    const response = await api
      .get('/api/select-financial-assets?mago=1')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  chatRecommendations: async () => {
    const response = await api
      .get('/api/select-chat-recommendations')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  signatureTypes: async () => {
    const response = await api
      .get('/api/select-signature-types')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  selectAnalyst: async () => {
    const response = await api
      .get('/api/select-analyst')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  chats: async () => {
    const response = await api
      .get('/api/select-chats')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  sellers: async () => {
    const response = await api
      .get('/api/select-seller')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  product: async () => {
    const response = await api
      .get('/api/select-product')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  productCategory: async () => {
    const response = await api
      .get('/api/select-product-category')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  productsByCategory: async (categoryId) => {
    const response = await api
      .get(`/api/select-product/${categoryId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  periodsByProduct: async (productId) => {
    const response = await api
      .get(`/api/select-product-access-period/${productId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  selectOrderStatus: async (params) => {
    const response = await api
      .get('/api/select-order-status', {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  selectCustomer: async (params) => {
    const response = await api
      .get('/api/select-customer', {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  selectSeller: async (params) => {
    const response = await api
      .get('/api/select-seller', {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  selectPlaylists: async (params) => {
    const response = await api
      .get('/api/select-playlists', {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  selectPaymentType: async (params) => {
    const response = await api
      .get('/api/select-payment-type', {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  selectCompany: async (params) => {
    const response = await api
      .get('/api/select-company', {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  typeRefund: async () => {
    const response = await api
      .get('/api/select-order-type-refund')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  ufId: async () => {
    const response = await api
      .get('/api/select-uf-id')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  cityId: async (ufId) => {
    const response = await api
      .get(`/api/select-cities-id/${ufId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getOptionsUpsell: async (ufId) => {
    const response = await api
      .get(`/api/select-product-by-commercial-table/${ufId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  courtesyTypes: async () => {
    const response = await api
      .get(`/api/select-courtesy-types`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  orderStatus: async () => {
    const response = await api
      .get(`/api/select-order-status`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  discountType: async () => {
    const response = await api
      .get(`/api/select-discount-type`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  accessAllProfile: async () => {
    const response = await api
      .get('/api/select-user-profile')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  studentStatus: async () => {
    const response = await api
      .get('/api/select-student-status')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  incomeType: async () => {
    const response = await api
      .get('/api/select-income-type')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  releasesType: async () => {
    const response = await api
      .get('/api/select-release-types')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  shoppingCartType: async () => {
    const response = await api
      .get('/api/select-shopping-cart-type')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  searchChats: async (params) => {
    const response = await api
      .get('/api/select-chats-search', {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getSelectTrail: async (params) => {
    const response = await api
      .get('/api/select-course-trial-type', {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  paymentDiscountType: async () => {
    const response = await api
      .get('/api/select-payment-discount-type ')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
