import axios from 'axios'
import queryString from 'query-string'
import { getUserSession, clearStorageUser } from '@utils'
import configLiberta from '../configs/config.liberta'

const api = axios.create({
  // baseURL: "https://api-liberta.homolog.dev.br"
  // baseURL: 'https://api-hml2.libertaplay.com.br'
  baseURL:
    window.location.origin === process.env.REACT_APP_LIBERTA_URL
      ? process.env.REACT_APP_LIBERTA_API_URL
      : process.env.REACT_APP_API_URL
})

api.interceptors.request.use(
  (config) => {
    const query = queryString.parse(window.location.search)
    let token = query?.token || getUserSession()

    if (window.location.pathname.includes('/token/')) {
      const str = window.location.pathname
      token = str.split('/')[2]
      token = `token=${window.atob(token)}`
      const query = queryString.parse(token)
      token = query?.token
    }

    if (token && !config.params?.notSendToken) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    const { status } = error.response
    if (
      status === 401 &&
      window.location.pathname !== '/carrinho' &&
      window.location.pathname !== '/pagamento/sucesso'
    ) {
      clearStorageUser()

      if (process.env.REACT_APP_ENV !== 'DEV') {
        const SITE_URL = process.env.REACT_APP_SITE_URL
        const LIBERTA_URL = process.env.REACT_APP_LIBERTA_URL

        const pathname = window.location.pathname
        const isSubject = pathname.includes('/lancamento/')
        const isLogin = pathname.includes('/login')
        const isMore =
          !!pathname.includes('/curso/') ||
          !!pathname.includes('/interna/') ||
          !!pathname.includes('/ebooks/')

        const isHome = configLiberta.nonLoggedLinkNavigation.some(
          (item) => !!pathname.includes(item)
        )
        const query = queryString.parse(window.location.search)

        if (!!query && !!query.code) {
          window.open(
            `${LIBERTA_URL}/home${query}`,
            '_self',
            'noopener noreferrer'
          )
          return
        }

        if (isSubject || isMore || isLogin || isHome) {
          return
        }

        window.open(SITE_URL, '_self', 'noopener noreferrer')
      }
    }
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    const token = getUserSession()
    if (token) {
      if (
        response.status === 401 &&
        window.location.pathname !== '/carrinho' &&
        window.location.pathname !== '/pagamento/sucesso'
      ) {
        clearStorageUser()

        if (process.env.REACT_APP_ENV !== 'DEV') {
          const SITE_URL = process.env.REACT_APP_SITE_URL
          const LIBERTA_URL = process.env.REACT_APP_LIBERTA_URL

          const pathname = window.location.pathname
          const isSubject = pathname.includes('/lancamento/')
          const isLogin = pathname.includes('/login')
          const isMore =
            !!pathname.includes('/curso/') ||
            !!pathname.includes('/interna/') ||
            !!pathname.includes('/ebooks/')
          const isHome = configLiberta.nonLoggedLinkNavigation.some(
            (item) => !!pathname.includes(item)
          )
          const query = queryString.parse(window.location.search)

          if (!!query && !!query.code) {
            window.open(
              `${LIBERTA_URL}/home${query}`,
              '_self',
              'noopener noreferrer'
            )
            return
          }
          if (isSubject || isMore || isLogin || isHome) {
            return
          }

          window.open(SITE_URL, '_self', 'noopener noreferrer')
        }

        return response
      }

      return response
    } else if (
      response.status === 401 &&
      window.location.pathname === '/carrinho'
    ) {
      return
    }

    return response
  },
  (error) => {
    const LIBERTA_URL = process.env.REACT_APP_LIBERTA_URL
    if (
      error.response.status === 401 &&
      window.location.pathname !== '/carrinho' &&
      window.location.pathname !== '/pagamento/sucesso'
    ) {
      clearStorageUser()

      if (process.env.REACT_APP_ENV !== 'DEV') {
        const SITE_URL = process.env.REACT_APP_SITE_URL

        const pathname = window.location.pathname
        const isSubject = pathname.includes('/lancamento/')
        const isLogin = pathname.includes('/login')
        const isMore =
          !!pathname.includes('/curso/') ||
          !!pathname.includes('/interna/') ||
          !!pathname.includes('/ebooks/')
        const isHome = configLiberta.nonLoggedLinkNavigation.some(
          (item) => !!pathname.includes(item)
        )
        const query = queryString.parse(window.location.search)

        if (!!query && !!query.code) {
          window.open(
            `${LIBERTA_URL}/home${query}`,
            '_self',
            'noopener noreferrer'
          )
          return
        }
        if (isSubject || isMore || isLogin || isHome) {
          return
        }

        window.open(SITE_URL, '_self', 'noopener noreferrer')
      }
    }
    if (
      error.response.status === 401 &&
      window.location.pathname === '/carrinho' &&
      window.location.origin.includes(LIBERTA_URL)
    ) {
      return
    }
    return Promise.reject(error)
  }
)

export default api
