import {useContext, createContext, useState, useEffect} from "react"
import config from "../../configs/config"
import {storageItem} from '@utils'
import {getOrigin} from "../../configs/themeConfig"

const ConfigsContext = createContext({})

function ConfigsProvider({ children }) {
  const [cmsOrigin] = useState(() => {
    return getOrigin()
  })

  useEffect(() => {
    const origin = getOrigin()

    storageItem('set', '@L&S::AppOrigin', origin)

    if (config[cmsOrigin].cssChanges) {
      const root = document.documentElement

      for (const change of config[cmsOrigin].cssChanges) {
        root.style.setProperty(change.property, `var(${change.value})`)
      }
    }
  }, [cmsOrigin])

  return (
    <ConfigsContext.Provider value={{ cmsOrigin }}>
      {children}
    </ConfigsContext.Provider>
  )
}

function useConfigs() {
  return useContext(ConfigsContext)
}

export { ConfigsProvider, useConfigs }
