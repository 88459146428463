import { useContext, createContext, useState } from 'react'
import { toast } from 'react-toastify'

import { ModalPix } from '../../components/ModalPix'
import orders from '../../services/orders'

const ModalPixContent = createContext({})

function ModalPixProvider({ children }) {
  const [openModalPix, setOpenModalPix] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(false)
  const handleCloseModalPix = () => {
    setOpenModalPix(false)
  }

  const getOrder = async (id) => {
    setIsLoading(true)

    let response = await orders.show(id)

    if (response.hasOwnProperty('response')) response = response.response

    if (response.status === 200) {
      if (response?.data?.status === 2) {
        setIsLoading(false)
        return toast.error('Pagamento já realizado')
      }

      setData(response.data)
      setOpenModalPix(true)
      setIsLoading(false)
      return
    }

    setIsLoading(false)

    const responseData = response.data

    if (responseData instanceof Array) {
      return toast.error(responseData[0])
    }

    if (typeof responseData?.error === 'string') {
      return toast.error(responseData?.error)
    }

    if (responseData instanceof Object) {
      return toast.error(Object.values(responseData).flat(Infinity)[0])
    }

    return toast.error(responseData)
  }

  const handleOpenModalPix = async (id) => {
    if (!!id) {
      await getOrder(id)
    }
  }

  return (
    <ModalPixContent.Provider
      value={{
        openModalPix,
        isLoading,
        handleOpenModalPix,
        handleCloseModalPix,
        data
      }}
    >
      <ModalPix isOpen={openModalPix} />
      {children}
    </ModalPixContent.Provider>
  )
}

function useModalPix() {
  return useContext(ModalPixContent)
}

export { ModalPixProvider, useModalPix }
