import { lazy } from 'react'
import { Svg } from '../components/Svg'

const background =
  require(`@src/assets/images/pages/login/liberta-background.png`).default
const logoWhite = require('@src/assets/images/logo/logo-liberta.svg').default
const logo =
  require('@src/assets/images/logo/menu-logo-liberta-white-open.svg').default
const locker = require('@src/assets/images/pages/liberta-locker.svg').default
const menuLogo =
  require('@src/assets/images/logo/menu-logo-liberta-dark.svg').default
const menuLogoOpen =
  require('@src/assets/images/logo/menu-logo-liberta-dark-open.svg').default
const menuLogoWhite =
  require('@src/assets/images/logo/liberta-logo-menu-white.svg').default
const menuLogoWhiteOpen =
  require('@src/assets/images/logo/menu-logo-liberta-white-open.svg').default

export default {
  images: {
    background: {
      src: background
    },
    logo: {
      src: logo,
      alt: 'Liberta'
    },
    logoWhite: {
      src: logoWhite,
      alt: 'Liberta'
    },
    locker: {
      src: locker,
      alt: 'Locker'
    },
    menuLogo: {
      src: menuLogo,
      alt: 'Liberta'
    },
    menuLogoOpen: {
      src: menuLogoOpen,
      alt: 'Liberta'
    },
    menuLogoWhite: {
      src: menuLogoWhite,
      alt: 'Liberta'
    },
    menuLogoWhiteOpen: {
      src: menuLogoWhiteOpen,
      alt: 'Liberta'
    }
  },
  badgeColor: 'success',
  bgFilter: '',
  welcomeMessage: 'WelcomeMessageLiberta',
  createMessage: 'CreateAccountMessageLiberta',
  course1: 'CourseAd 1 liberta',
  course2: 'CourseAd 2 liberta',
  course3: 'CourseAd 3 liberta',
  course4: 'CourseAd 4 liberta',
  cssChanges: [
    { property: '--primary', value: '--primary-liberta' },
    { property: '--menu-gradient', value: '--menu-gradient-liberta' },
    { property: '--background', value: '--background-liberta' },
    { property: '--background-dark', value: '--background-dark-liberta' },
    { property: '--menu-color', value: '--menu-color-liberta' },
    { property: '--dark-theme-heading', value: '--dark-theme-heading-liberta' },
    { property: '--scrollbar-color', value: '--scrollbar-color-liberta' },
    { property: '--hover-background', value: '--hover-background-liberta' },
    { property: '--prev-icon', value: '--prev-icon-liberta' },
    { property: '--next-icon', value: '--next-icon-liberta' },
    { property: '--stepper-shadow', value: '--stepper-shadow-liberta' },
    { property: '--stepper-passed', value: '--stepper-passed-liberta' },
    { property: '--r-select-bg-clr', value: '--r-select-bg-clr-liberta' },
    { property: '--nav-after-rgba', value: '--nav-after-rgba-liberta' },
    { property: '--stepper-passed', value: '--stepper-passed-liberta' },
    { property: '--active-menu-color', value: '--active-menu-color-liberta' },
    { property: '--cart-coupon-p', value: '--cart-coupon-p-liberta' },
    {
      property: '--cart-coupon-address-select',
      value: '--cart-coupon-address-select-liberta'
    },
    { property: '--cart-green', value: '--cart-green-liberta' }
  ],
  routes: [
    {
      path: '/home',
      exact: true,
      component: lazy(() => import('../views/Home')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/categorias',
      exact: true,
      component: lazy(() => import('../views/Categories/List')),
      meta: {
        action: 'read',
        resource: 'Categorias'
      }
    },
    {
      path: '/carrinho',
      exact: true,
      component: lazy(() => import('../views/CartLiberta')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/qualificacao-de-conteudo',
      exact: true,
      component: lazy(() => import('../views/ContentQualification/List')),
      meta: {
        action: 'read',
        resource: 'Qualificacao'
      }
    },
    {
      path: '/qualificacao-de-conteudo/cadastro',
      exact: true,
      component: lazy(() => import('../views/ContentQualification/Create')),
      meta: {
        action: 'create',
        resource: 'Qualificacao'
      }
    },
    {
      path: '/qualificacao-de-conteudo/:slug',
      exact: true,
      component: lazy(() => import('../views/ContentQualification/Create')),
      meta: {
        action: 'update',
        resource: 'Qualificacao'
      }
    },
    {
      path: '/calendario-do-investidor',
      exact: true,
      component: lazy(() => import('../views/InvestorCalendar/List')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/calendario-do-investidor/cadastro',
      exact: true,
      component: lazy(() => import('../views/InvestorCalendar/Create')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/calendario-do-investidor/:id',
      exact: true,
      component: lazy(() => import('../views/InvestorCalendar/Create')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/podcasts',
      exact: true,
      component: lazy(() => import('../views/Podcasts/List')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/podcasts',
      exact: true,
      component: lazy(() => import('../views/Podcasts/List')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/podcasts/cadastro',
      exact: true,
      component: lazy(() => import('../views/Podcasts/Create')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/podcasts/:id',
      exact: true,
      component: lazy(() => import('../views/Podcasts/Create')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/relatorios-research',
      exact: true,
      component: lazy(() => import('../views/LibertaReports/List')),
      meta: {
        action: 'read',
        resource: 'Research'
      }
    },
    {
      path: '/relatorios-research/cadastro',
      exact: true,
      component: lazy(() => import('../views/LibertaReports/Create')),
      meta: {
        action: 'create',
        resource: 'Research'
      }
    },
    {
      path: '/relatorios-research/:id',
      exact: true,
      component: lazy(() => import('../views/LibertaReports/Create')),
      meta: {
        action: 'update',
        resource: 'Research'
      }
    },
    {
      path: '/trilhas',
      exact: true,
      component: lazy(() => import('../views/Trails/List')),
      meta: {
        action: 'read',
        resource: 'Trilhas'
      }
    },
    {
      path: '/trilhas/cadastro',
      exact: true,
      component: lazy(() => import('../views/Trails/Create')),
      meta: {
        action: 'create',
        resource: 'Trilhas'
      }
    },
    {
      path: '/trilhas/:id',
      exact: true,
      component: lazy(() => import('../views/Trails/Create')),
      meta: {
        action: 'update',
        resource: 'Trilhas'
      }
    },
    {
      path: '/lancamento/:slug',
      exact: true,
      component: lazy(() => import('../views/Launch/Slug')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/lancamentos',
      exact: true,
      component: lazy(() => import('../views/Launch/List')),
      meta: {
        action: 'read',
        resource: 'Lançamentos'
      }
    },
    {
      path: '/lancamentos/cadastro',
      exact: true,
      component: lazy(() => import('../views/Launch/Create')),
      meta: {
        action: 'create',
        resource: 'Lançamentos'
      }
    },
    {
      path: '/lancamentos/:id',
      exact: true,
      component: lazy(() => import('../views/Launch/Create')),
      meta: {
        action: 'update',
        resource: 'Lançamentos'
      }
    },
    {
      path: '/influencer',
      exact: true,
      component: lazy(() => import('../views/Influencer/List')),
      meta: {
        action: 'read',
        resource: 'Influencer'
      }
    },
    {
      path: '/politicas-internas',
      exact: true,
      component: lazy(() => import('../views/InternalPolicies/List')),
      meta: {
        action: 'read',
        resource: 'Políticas internas'
      }
    },
    {
      path: '/politicas-internas/cadastro',
      exact: true,
      component: lazy(() => import('../views/InternalPolicies/Create')),
      meta: {
        action: 'create',
        resource: 'Políticas internas'
      }
    },
    {
      path: '/politicas-internas/:id',
      exact: true,
      component: lazy(() => import('../views/InternalPolicies/Create')),
      meta: {
        action: 'update',
        resource: 'Políticas internas'
      }
    },

    {
      path: '/liberta-store',
      exact: true,
      component: lazy(() => import('../views/LibertaStore/List')),
      meta: {
        action: 'read',
        resource: 'Liberta Store Cadastro'
      }
    },
    {
      path: '/influencer/cadastro',
      exact: true,
      component: lazy(() => import('../views/Influencer/Create')),
      meta: {
        action: 'create',
        resource: 'Influencer'
      }
    },
    {
      path: '/cores',
      exact: true,
      component: lazy(() => import('../views/Colors/List')),
      meta: {
        action: 'read',
        resource: 'Liberta Store Cores'
      }
    },
    {
      path: '/liberta-store/cadastro',
      exact: true,
      component: lazy(() => import('../views/LibertaStore/Create')),
      meta: {
        action: 'create',
        resource: 'Liberta Store Cadastro'
      }
    },
    {
      path: '/liberta-store/:id',
      exact: true,
      component: lazy(() => import('../views/LibertaStore/Create')),
      meta: {
        action: 'update',
        resource: 'Liberta Store Cadastro'
      }
    },
    {
      path: '/influencer/:id',
      exact: true,
      component: lazy(() => import('../views/Influencer/Create')),
      meta: {
        action: 'update',
        resource: 'Influencer'
      }
    },
    {
      path: '/assunto/:slug',
      exact: true,
      component: lazy(() => import('../views/Subjects')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/minhas-trilhas',
      exact: true,
      component: lazy(() => import('../views/TrailsClient')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/minhas-trilhas/:id',
      exact: true,
      component: lazy(() => import('../views/TrailsClient/TrailsId')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/conteudo/video/:id',
      exact: true,
      component: lazy(() => import('../views/VideoPlay')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/conteudo/video/:id/live',
      exact: true,
      component: lazy(() => import('../views/VideoPlay/Video')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/conteudo/video/:id/recomendacoes',
      exact: true,
      component: lazy(() => import('../views/VideoPlay/Recomendations')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/conteudo/video/:id/chat',
      exact: true,
      component: lazy(() => import('../views/VideoPlay/Chat')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/flutuante',
      component: lazy(() => import('../views/Floating')),
      layout: 'BlankLayout',
      meta: {
        action: 'read',
        resource: 'Cliente-Transmissões'
      }
    },
    {
      path: '/research',
      exact: true,
      component: lazy(() => import('../views/Research/List')),
      meta: {
        action: 'read',
        resource: 'Cliente-Research'
      }
    },
    {
      path: '/research/:id',
      exact: true,
      component: lazy(() => import('../views/Research')),
      meta: {
        action: 'read',
        resource: 'Cliente-Research'
      }
    },
    {
      path: '/assinatura/cadastro',
      exact: true,
      component: lazy(() => import('../views/NewSignature/Create')),
      meta: {
        action: 'create',
        resource: 'Tipos de Assinatura'
      }
    },
    {
      path: '/assinatura/:id',
      exact: true,
      component: lazy(() => import('../views/NewSignature/Create')),
      meta: {
        action: 'update',
        resource: 'Tipos de Assinatura'
      }
    },
    {
      path: '/assinatura',
      exact: true,
      component: lazy(() => import('../views/NewSignature/List')),
      meta: {
        action: 'read',
        resource: 'Tipos de Assinatura'
      }
    },
    {
      path: '/loja',
      exact: true,
      component: lazy(() => import('../views/Store')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/liberacao-cliente-xp',
      exact: true,
      component: lazy(() => import('../views/XpRelease/List')),
      meta: {
        action: 'read',
        resource: 'Liberação de cliente XP'
      }
    },
    {
      path: '/liberacao-cliente-xp/cadastro',
      exact: true,
      component: lazy(() => import('../views/XpRelease/Create')),
      meta: {
        action: 'create',
        resource: 'Liberação de cliente XP'
      }
    },
    {
      path: '/liberacao-cliente-xp/:id',
      exact: true,
      component: lazy(() => import('../views/XpRelease/List')),
      meta: {
        action: 'update',
        resource: 'Liberação de cliente XP'
      }
    },
    {
      path: '/assinaturas',
      exact: true,
      component: lazy(() => import('../views/SignaturePlans')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/recomendacoes',
      exact: true,
      component: lazy(() =>
        import('../views/GroupRecomendation/List/index.jsx')
      ),
      meta: {
        action: 'read',
        resource: 'Listagem Recomendações'
      }
    },
    {
      path: '/gestao-de-tickers',
      exact: true,
      component: lazy(() => import('../views/TickersMagnament/List')),
      meta: {
        action: 'read',
        resource: 'Gestão de Tickers'
      }
    },
    {
      path: '/gestao-de-tickers/cadastro',
      exact: true,
      component: lazy(() => import('../views/TickersMagnament/Create')),
      meta: {
        action: 'create',
        resource: 'Gestão de Tickers'
      }
    },
    {
      path: '/gestao-de-tickers/editar/:id',
      exact: true,
      component: lazy(() => import('../views/TickersMagnament/Create')),
      meta: {
        action: 'update',
        resource: 'Gestão de Tickers'
      }
    },
    {
      path: '/recomendacoes/cadastro',
      exact: true,
      component: lazy(() => import('../views/GroupRecomendation/Create')),
      meta: {
        action: 'create',
        resource: 'Recomendações'
      }
    },
    {
      path: '/recomendacoes/:id',
      exact: true,
      component: lazy(() => import('../views/GroupRecomendation/Create')),
      meta: {
        action: 'update',
        resource: 'Recomendações'
      }
    },
    {
      path: '/status-das-recomendacoes',
      exact: true,
      component: lazy(() => import('../views/Recomendations/index.jsx')),
      meta: {
        action: 'read',
        resource: 'Listagem Recomendações'
      }
    },
    {
      path: '/assinaturas/:slug',
      exact: true,
      component: lazy(() => import('../views/SignaturePlans/Slug')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/curso/:slug',
      exact: true,
      component: lazy(() => import('../views/UserCourses/Slug')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/ebooks/:slug',
      exact: true,
      component: lazy(() => import('../views/EBooksUsers/Slug')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/interna/:slug',
      exact: true,
      component: lazy(() => import('../views/SubscriptionInformation')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/hub/sales-force',
      exact: true,
      component: lazy(() => import('../views/Assessor/SalesForce')),
      meta: {
        action: 'read',
        resource: 'hub-sales-force'
      }
    },
    // {
    //   path: '/hub/4-shark',
    //   exact: true,
    //   component: lazy(() => import('../views/Assessor/FourShark')),
    //   meta: {
    //     action: 'read',
    //     resource: 'hub-four-shark'
    //   }
    // },
    {
      path: '/hub/home',
      exact: true,
      component: lazy(() => import('../views/Assessor/Home')),
      meta: {
        action: 'read',
        resource: 'hub-home'
      }
    },
    {
      path: '/hub/bi/:slug',
      exact: true,
      component: lazy(() => import('../views/Assessor/Bi')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/faq/editar/:id',
      exact: true,
      component: lazy(() => import('../views/Faq/Create')),
      meta: {
        action: 'read',
        resource: 'FAQ / Central de ajuda'
      }
    },
    {
      path: '/faq/cadastro',
      exact: true,
      component: lazy(() => import('../views/Faq/Create')),
      meta: {
        action: 'read',
        resource: 'FAQ / Central de ajuda'
      }
    },
    {
      path: '/faq',
      exact: true,
      component: lazy(() => import('../views/Faq/List')),
      meta: {
        action: 'read',
        resource: 'Nps'
      }
    },
    {
      path: '/nps',
      exact: true,
      component: lazy(() => import('../views/Nps/List')),
      meta: {
        action: 'read',
        resource: 'Nps'
      }
    },
    {
      path: '/nps/cadastro',
      exact: true,
      component: lazy(() => import('../views/Nps/Create')),
      meta: {
        action: 'read',
        resource: 'Nps'
      }
    },
    {
      path: '/nps/editar/:id',
      exact: true,
      component: lazy(() => import('../views/Nps/Create')),
      meta: {
        action: 'read',
        resource: 'Nps'
      }
    },
    {
      path: '/nps/enviar-pesquisa',
      exact: true,
      component: lazy(() => import('../views/Nps/SendSearch')),
      meta: {
        action: 'read',
        resource: 'Nps'
      }
    },
    {
      path: '/agenda-colaborador',
      exact: true,
      component: lazy(() => import('../views/CollaboratorsAgenda/List')),
      meta: {
        action: 'read',
        resource: 'Agenda do Colaborador'
      }
    },
    {
      path: '/hub/central-de-chamdos',
      exact: true,
      component: lazy(() => import('../views/Assessor/Glpi')),
      meta: {
        action: 'read',
        resource: 'hub-glpi'
      }
    },
    {
      path: '/hub/politicas-procedimentos',
      exact: true,
      component: lazy(() => import('../views/PoliciesAndProcedures')),
      meta: {
        action: 'read',
        resource: 'hub-home'
      }
    },
    {
      path: '/hub/politicas-procedimentos/:id',
      exact: true,
      component: lazy(() =>
        import('../views/PoliciesAndProcedures/ViewPolicesAndProcedures')
      ),
      meta: {
        action: 'read',
        resource: 'hub-politicas'
      }
    },
    {
      path: '/hub/liberta-store',
      exact: true,
      component: lazy(() => import('../views/Assessor/Store')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/hub/liberta-store/:slug',
      exact: true,
      component: lazy(() => import('../views/Assessor/Store/Product')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/cliente/recomendacoes',
      exact: true,
      component: lazy(() => import('../views/RecommendationsList')),
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/metodos',
      exact: true,
      component: lazy(() => import('../views/Methods/List')),
      meta: {
        action: 'read',
        resource: 'Métodos'
      }
    }
  ],
  defaultRoute: '/home',
  changeMenu: [
    {
      id: 'home',
      title: 'Início',
      icon: <Svg name="home" />,
      navLink: '/home',
      action: 'read',
      resource: 'Cliente-Home'
    },
    {
      id: 'tv-liberta',
      title: 'TV Liberta',
      icon: <Svg name="menu-broadcasts" />,
      navLink: '',
      action: 'read',
      resource: 'Cliente-Home',
      customClass: 'hide-menu'
    },
    {
      id: 'mercado-ao-vivo',
      title: 'Mercado ao vivo',
      icon: <Svg name="menu-broadcasts" />,
      navLink: '',
      action: 'read',
      resource: 'Cliente-Home',
      customClass: 'hide-menu'
    },
    {
      id: 'courses',
      title: 'Meus Cursos / Ebooks',
      icon: <Svg name="courses" />,
      navLink: '/assunto/cursos',
      action: 'read',
      resource: 'Cliente-cursos'
    },
    {
      id: 'signature-plans',
      title: 'Assinaturas',
      icon: <Svg name="signature-plans" />,
      navLink: '/assinaturas',
      action: 'read',
      resource: 'Cliente-cursos'
    },
    {
      id: 'store',
      title: 'Loja',
      icon: <Svg name="store" />,
      navLink: '/loja',
      action: 'read',
      resource: 'Cliente-cursos'
    },
    {
      id: 'trilhas',
      title: 'Iniciar Trilha',
      icon: <Svg name="trail-bar-icon" />,
      navLink: '/minhas-trilhas',
      action: 'read',
      resource: 'Home - Trilhas'
    },
    {
      id: 'recommended-wallet',
      title: 'Recomendações',
      icon: <Svg name="group" />,
      navLink: '/cliente/recomendacoes',
      action: 'read',
      resource: 'Cliente-Home'
    },

    {
      id: 'research',
      title: 'Relatórios',
      icon: <Svg name="research" />,
      navLink: '/research',
      action: 'read',
      resource: 'Cliente-Research'
    }
  ],
  nonLoggedResource: [
    'Cliente-cursos',
    'Home - Trilhas',
    'Cliente-Home',
    'Cliente-Research'
  ],
  nonLoggedLinkNavigation: [
    '/loja',
    '/assunto/cursos',
    '/assinaturas',
    `/home`,
    '`/ebooks`'
  ]
}
