import { useState } from "react"

export const useFloating = () => {
  const [content, setContent] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [contentUrl, setContentUrl] = useState(null)
  const [broadcastDataFloating, setBroadcastDataFloating] = useState(null)
  const [isMenuCollapsedContext, setIsMenuCollapsedContext] = useState(null)
  const [videoCurrentTime, setVideoCurrentTime] = useState(0)

  return {
    content,
    setContent,
    isOpen,
    setIsOpen,
    contentUrl,
    videoCurrentTime,
    setVideoCurrentTime,
    setContentUrl,
    broadcastDataFloating,
    setBroadcastDataFloating,
    isMenuCollapsedContext,
    setIsMenuCollapsedContext
  }
}
