import api from './api'

export default {
  get: async (params) => {
    const response = await api
      .get('/api/list-orders', {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  show: async (id) => {
    const response = await api
      .get(`/api/find-order/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
      return response
  },
  details: async (id) => {
    const response = await api
      .get(`/api/find-order-details/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  orderRefund: async (orderId, data) => {
    const response = await api
      .post(`/api/create-order-refund/${orderId}`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  // pending
  pending: async (params) => {
    const response = await api.get('/api/list-pending-orders', {
      params
    })
        .then(({ ...response }) => response)
        .catch(({ ...response }) => response)

    return response
  },
  confirm: async (id) => {
    const response = await api.put(`/api/confirm-order-payment/${id}`)
        .then(({ ...response }) => response)
        .catch(({ ...response }) => response)

    return response
  },
  delete: async (id) => {
    const response = await api.put(`/api/cancel-order-payment/${id}`)
        .then(({ ...response }) => response)
        .catch(({ ...response }) => response)

    return response
  }
  // pending end
}
