import { createPortal } from 'react-dom'
import { useState, useContext } from 'react'
import Draggable from 'react-draggable'
import { Card, Badge } from 'reactstrap'
import { X } from 'react-feather'
import classNames from 'classnames'

import { FloatingContext } from '../../utility/context/Floating'

import styles from './styles.module.scss'
import './styles.scss'

export const FloatingCard = () => {
  const [isDragging, setIsDragging] = useState(false)

  const { setIsOpen, isOpen, content, broadcastDataFloating, videoCurrentTime } = useContext(FloatingContext)

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleBroadcast = () => {
    setIsOpen(false)
    if (!window.location.href.includes(`cliente/lives/${broadcastDataFloating.id}`)) {
      return window.open(`/cliente/lives/${broadcastDataFloating.id}?current_time=${videoCurrentTime}`, '_self')
    }
  }

  const floatingCardClassNames = classNames({
    [styles.floatingCard]: true,
    [styles.dragging]: isDragging
  })

  const body = document.getElementsByTagName('body')[0]

  return (
    createPortal(
      <>
        {isOpen && (
          <Draggable
            onStart={() => setIsDragging(true)}
            onStop={() => setIsDragging(false)}
            positionOffset={{
              x: `calc(${body.offsetWidth}px - 650px)`,
              y: 'calc(-100% - 30px)'
            }}
          >
            <div>
              <Card className={floatingCardClassNames}>
                <div className={styles.cardActions}>
                  <div className={styles.badge}>
                    {content?.badge && (
                      <Badge color={content?.badge?.color || 'light-primary'} pill>
                        {content?.badge?.content || ''}
                      </Badge>
                    )}
                  </div>
                  <div className={styles.cardControls}>
                    <div>
                      <span style={{color: "#0077BD", cursor: "pointer"}} onClick={handleBroadcast}>Voltar à sua transmissão</span>
                    </div>
                    <div onClick={handleClose}>
                      <X size={24} strokeWidth={1.5} />
                    </div>
                  </div>
                </div>

                <div className={`${styles.cardContent} react-draggable-content-id`}>
                  {content?.body}
                </div>
              </Card>
            </div>
          </Draggable>
        )}
      </>,
      document.getElementsByTagName('body')[0]
    )
  )
}
