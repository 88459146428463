import api from './api'

export default {
  get: async (params) => {
    const response = await api
      .get('/api/list-nps-questions', {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  create: async (data) => {
    const response = await api
      .post('/api/create-nps-question', data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  update: async (data, id) => {
    const response = await api
      .post(`/api/update-nps-question/${id}`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getInfo: async (id) => {
    const response = await api
      .get(`/api/find-nps-question/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  delete: async (id) => {
    const response = await api
      .delete(`/api/delete-nps-question/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  deleteQuestionNpsUser: async (id) => {
    const response = await api
      .delete(`/api/delete-nps-question-user/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  active: async (id) => {
    const response = await api
      .post(`/api/toggle-active-nps-question/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getCategories: async () => {
    const response = await api
      .get(`/api/select-nps-question-categories`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getListQuestionNpsUser: async (id) => {
    const response = await api
      .get(`/api/list-question-nps-user/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getSeeComment: async (id) => {
    const response = await api
      .get(`/api/find-nps-question-user/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  checkUsersNps: async (data) => {
    const response = await api
      .post('/api/check-users-nps-send', data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  sendSearch: async (data) => {
    const response = await api
      .post('/api/send-nps-users', data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  createNpsAnswer: async (id, data) => {
    const response = await api
      .post(`/api/create-nps-answer-user`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getListAllQuestionNps: async () => {
    const response = await api
      .get(`/api/list-all-question-nps-user`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
