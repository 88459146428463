// ** React Imports
import { Suspense, lazy, useEffect } from 'react'
import ReactDOM from 'react-dom'

const cookies = navigator.cookieEnabled
import queryString from 'query-string'

// ** Redux Imports
import { Provider } from 'react-redux'
// import {store} from './redux/storeConfig/store'

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'
import { IntlProviderWrapper } from './utility/context/Internationalization'

// import ability from './configs/acl/ability'
// import {AbilityContext} from './utility/context/Can'

// ** FloatingCard
import { FloatingCard } from './components/FloatingCard'
import { FloatingProvider } from './utility/context/Floating'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

import { BroadcastsProvider } from './utility/context/Broadcasts'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Calendar
import '@src/assets/scss/DatePicker.scss'
import '@src/assets/scss/Calendar.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** React-Grid-Layout styles
import '../node_modules/react-grid-layout/css/styles.css'
import '../node_modules/react-resizable/css/styles.css'

import 'bootstrap/dist/css/bootstrap.css'

// ** Service Worker
import * as serviceWorker from './serviceWorker'

import { FormProvider, useForm } from 'react-hook-form'
import { ProfileProvider } from './utility/hooks/useProfile'
import { ModalProvider } from './utility/hooks/useModal'
import { RecommendationsProvider } from './utility/hooks/useRecommendations'
import { ConfigsProvider } from './utility/hooks/useConfigs'
import { OnboardingProvider } from './utility/hooks/useOnboarding'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

let App

import TagManager from 'react-gtm-module'
import { storageItem, saveUtmOnCookies } from '@utils'
import { CookiesSettings } from './components/CookiesSettings'
import { isTv } from './utility/Utils'
import { getOrigin } from './configs/themeConfig'
import { ModeThemeProvider } from './utility/hooks/useTheme'
import { FormUserRequiredProvider } from './utility/hooks/useFormUserRequired'
import { NPSProvider } from './utility/hooks/useNPS'
import { ModalPixProvider } from './utility/hooks/useModalPix'
import { HomeCourseProvider } from './utility/hooks/useHomeCourse'
import { TrailProvider } from './utility/hooks/useTrail'
import { IOs16Provider } from './utility/hooks/useIOs16'
import { SessionProvider } from './utility/hooks/useSession'

const NewTagManagerArgs = {
  gtmId: process.env.REACT_APP_NEW_GTM_ID
}

TagManager.initialize(NewTagManagerArgs)

// if (window.location.origin === process.env.REACT_APP_LIBERTA_URL) {
//   document.title = 'libertaPlay'
//   document.getElementById('favicon').href = '/favicon-liberta.ico'
// } else {
//   document.title = 'L&S'
//   document.getElementById('favicon').href = '/favicon-ls.ico'
// }

if (!cookies) {
  App = () => {
    useEffect(() => {
      if (isTv() && getOrigin() === 'liberta') {
        window.location.href = 'https://tv.libertaplay.com.br'
      }
    }, [])
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <p
          style={{
            width: '800px',
            maxWidth: '80%',
            textAlign: 'center',
            margin: 0
          }}
        >
          Prezados clientes, buscando melhorar nossos serviços, estamos passando
          por alguns ajustes no modulo do carrinho apenas para sistemas em seus
          dispositivos móveis, tente efetuar sua compra de forma temporária em
          uma estação convencional. Logo voltaremos com o serviço e você
          conseguirá novamente efetuar suas compras em seu celular. Obrigado
          pela compreensão.
        </p>
      </div>
    )
  }
} else {
  const { store } = require('./redux/storeConfig/store')
  const abilityDefault = require('./configs/acl/ability')
  const { AbilityContext } = require('./utility/context/Can')
  const ability = abilityDefault.default

  App = () => {
    useEffect(() => {
      if (isTv() && getOrigin() === 'liberta') {
        window.location.href = 'https://tv.libertaplay.com.br'
      }
    }, [])

    const methods = useForm()

    function getElementsByAttribute(attribute) {
      const matchingElements = []
      const allElements = document.getElementsByTagName('*')
      for (let i = 0, n = allElements.length; i < n; i++) {
        // console.log({attribute: allElements[i].getAttribute(attribute)})
        if (allElements[i].getAttribute(attribute) !== null) {
          matchingElements.push(allElements[i])
        }
      }
      return matchingElements
    }

    const query = queryString.parse(window.location.search)

    useEffect(() => {
      saveUtmOnCookies(
        query?.utm_source,
        query?.utm_medium,
        query?.utm_campaign,
        query?.utm_content,
        query?.utm_term,
        query?.utm_influenciador,
        query?.utm_objetivo
      )
    }, [query])

    useEffect(() => {
      getElementsByAttribute('role')
    }, [])

    useEffect(() => {
      if (window.location.origin === process.env.REACT_APP_LIBERTA_URL) {
        // if (window.location.origin === 'http://127.0.0.1:3000') {
        return
      }
      if (
        document?.body &&
        window.location.href.includes('carrinho') &&
        !window.location.href.includes('carrinhos')
      ) {
        if (!storageItem('get', 'oldSkin')) {
          storageItem('set', 'oldSkin', storageItem('get', 'skin'))
        }
        storageItem('set', 'skin', 'light')
      } else {
        if (storageItem('get', 'oldSkin')) {
          storageItem('set', 'skin', storageItem('get', 'oldSkin'))
          storageItem('remove', 'oldSkin')
        }
      }
    }, [])

    return (
      <Provider store={store}>
        <SessionProvider>
          <FormProvider {...methods}>
            <Suspense
              fallback={
                window.location.href.includes('carrinho') &&
                !window.location.href.includes('carrinhos') ? (
                  <></>
                ) : (
                  <Spinner color="primary-default" />
                )
              }
            >
              <FormUserRequiredProvider>
                <AbilityContext.Provider value={ability}>
                  <ThemeContext>
                    <ModeThemeProvider>
                      <IntlProviderWrapper>
                        <ModalPixProvider>
                          <HomeCourseProvider>
                            <NPSProvider>
                              <IOs16Provider>
                                <ConfigsProvider>
                                  <FloatingProvider>
                                    <BroadcastsProvider>
                                      <ProfileProvider>
                                        <ModalProvider>
                                          <OnboardingProvider>
                                            <RecommendationsProvider>
                                              <TrailProvider>
                                                <LazyApp />
                                                <FloatingCard />
                                                <CookiesSettings />
                                                <ToastContainer newestOnTop />
                                              </TrailProvider>
                                            </RecommendationsProvider>
                                          </OnboardingProvider>
                                        </ModalProvider>
                                      </ProfileProvider>
                                    </BroadcastsProvider>
                                  </FloatingProvider>
                                </ConfigsProvider>
                              </IOs16Provider>
                            </NPSProvider>
                          </HomeCourseProvider>
                        </ModalPixProvider>
                      </IntlProviderWrapper>
                    </ModeThemeProvider>
                  </ThemeContext>
                </AbilityContext.Provider>
              </FormUserRequiredProvider>
            </Suspense>
          </FormProvider>
        </SessionProvider>
      </Provider>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
