import {useContext, createContext, useState} from "react"


const OnboardingContext = createContext({})

function OnboardingProvider({children}) {
  const [isOpen, setIsOpen] = useState(false)
  const [steps, setSteps] = useState([])
  const [formData, setFormData] = useState({})
  const [showNotification, setShowNotification] = useState(false)

  return (
    <OnboardingContext.Provider value={{isOpen, setIsOpen, steps, setSteps, formData, setFormData, showNotification, setShowNotification}}>
      {children}
    </OnboardingContext.Provider>
  )
}

function useOnboarding() {
  return useContext(OnboardingContext)
}

export {OnboardingProvider, useOnboarding}
