import { Slider, styled } from '@mui/material'

export const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 6,
  '& .MuiSlider-track': {
    border: 'none',
    background: 'linear-gradient(90deg, #1BD560 0%, #A8E063 100%)'
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 22,
    height: 22,
    fontWeight: "bold",
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#000',
    color: "#1BD560",
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
    },
    '& > *': {
      transform: 'rotate(45deg)'
    }
  }
})
