import { useContext, createContext, useState } from 'react'

const HomeCourseContent = createContext({})

function HomeCourseProvider({ children }) {
  const [openHomeCourse, setOpenHomeCourse] = useState(true)

  const handleCloseHomeCourse = () => {
    setOpenHomeCourse(false)
  }
  const handleOpenHomeCourse = () => {
    setOpenHomeCourse(true)
  }

  return (
    <HomeCourseContent.Provider
      value={{
        openHomeCourse,
        handleCloseHomeCourse,
        handleOpenHomeCourse
      }}
    >
      {children}
    </HomeCourseContent.Provider>
  )
}

function useHomeCourse() {
  return useContext(HomeCourseContent)
}

export { HomeCourseProvider, useHomeCourse }
