import { useState, createContext } from 'react'

const BroadcastsContext = createContext()

const BroadcastsProvider = ({ children }) => {
  const [broadcasts, setBroadcasts] = useState([])
  const [signatures, setSignatures] = useState()
  const [broadcastDelay, setBroadcastDelay] = useState(0)

  return (
    <BroadcastsContext.Provider
      value={{
        broadcasts,
        setBroadcasts,
        broadcastDelay,
        setBroadcastDelay,
        signatures,
        setSignatures
      }}
    >
      {children}
    </BroadcastsContext.Provider>
  )
}

export { BroadcastsContext, BroadcastsProvider }
