import axios from 'axios'

const api = axios.create({
  // baseURL: "https://api-ls.homolog.dev.br"
  // baseURL: 'https://api-hml2.libertaplay.com.br'
  baseURL:
    window.location.origin === process.env.REACT_APP_LIBERTA_URL
      ? process.env.REACT_APP_LIBERTA_API_URL
      : process.env.REACT_APP_API_URL
})

export default {
  getCart: async (cartId, token) => {
    return await api
      .get(`/api/find-shopping-cart/${cartId}`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
  },
  findOrCreateCart: async (token) => {
    return await api
      .get(`/api/ls-brazil-customer-cart`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
  },
  deleteProduct: async (productId, token) => {
    return await api
      .post(
        `api/shopping-cart-delete-product/${productId}`,
        {},
        { headers: { authorization: `Bearer ${token}` } }
      )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
  },
  updateCartProductAmount: async (cartId, data, token) => {
    return await api
      .post(`api/update-shopping-cart-product-amount/${cartId}`, data, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
  },
  finishCartOrder: async (cartId, data, headers) => {
    return await api
      .post(`api/finish-cart-order/${cartId}`, data, {
        headers
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
  },
  applyCoupon: async (cartId, data, token) => {
    return await api
      .put(`api/shopping-cart-apply-coupon/${cartId}`, data, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
  },
  applyCouponNoLogin: async (id, data) => {
    const response = await api
      .put(`api/shopping-cart-apply-coupon-no-login/${id}`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  removeCoupon: async (cartId, data, token) => {
    return await api
      .put(`api/shopping-cart-remove-coupon/${cartId}`, data, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
  },
  getInstallments: async (id, token) => {
    return await api
      .get(`/api/select-shopping-cart-installments/${id}`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
  },
  showCustomerDetails: async (id, token) => {
    const response = await api
      .get(`/api/customer-details/${id}`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  ufId: async (token) => {
    const response = await api
      .get('/api/select-uf-id', {
        headers: { authorization: `Bearer ${token}` }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  cityId: async (ufId, token) => {
    const response = await api
      .get(`/api/select-cities-id/${ufId}`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  createAddress: async (data, token) => {
    const response = await api
      .post(`/api/create-user-address`, data, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getAddressByCep: async (zip_code, token) => {
    const response = await api
      .get(`/api/consult-zip-code`, {
        headers: { authorization: `Bearer ${token}` },
        params: { zip_code }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  addProduct: async (data, headers) => {
    const response = await api
      .post(`/api/shopping-cart-add-product`, data, { headers })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  addProductWithoutLogin: async (data, email) => {
    const response = await api
      .post(`/api/shopping-cart-add-product-no-login?email=${email}`, data)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getStatusCart: async (id, token) => {
    const response = await api
      .get(`api/find-order/${id}`, {
        headers: { authorization: `Bearer ${token}` }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  user: async (token) => {
    const response = await api
      .get(`api/user`, { headers: { authorization: `Bearer ${token}` } })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  customerCarts: async (token, cartId) => {
    const response = await api
      .get(`api/ls-brazil-customer-carts`, {
        params: {
          shopping_cart_id: cartId
        },
        headers: { authorization: `Bearer ${token}` }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getDiscount: async (id, token, paymentId) => {
    const response = await api
      .post(
        `api/recalculate-discount-with-payment-type/${id}`,
        {
          payment_type_id: paymentId
        },
        {
          headers: { authorization: `Bearer ${token}` }
        }
      )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
