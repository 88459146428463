import { Svg } from '../components/Svg'
import {lazy} from "react"

const background = require(`@src/assets/images/pages/login/login_background.png`).default
const logoWhite = require('@src/assets/images/logo/logo_white.svg').default
const logo = require('@src/assets/images/logo/logo.svg').default
const locker = require('@src/assets/images/pages/locker.svg').default

export default {
  images: {
    background: {
      src: background
    },
    logo: {
      src: logo,
      alt: 'L&S'
    },
    logoWhite: {
      src: logoWhite,
      alt: 'L&S'
    },
    locker: {
      src: locker,
      alt: 'Locker'
    },
    menuLogo: {
      src: logoWhite,
      alt: 'L&S'
    }
  },
  badgeColor: 'danger',
  bgFilter: '',
  welcomeMessage: 'WelcomeMessage',
  createMessage: 'CreateAccountMessage',
  course1: 'CourseAd 1',
  course2: 'CourseAd 2',
  course3: 'CourseAd 3',
  course4: 'CourseAd 4',
  routes: [
    {
      path: "/carrinho",
      exact: true,
      component: lazy(() => import("../views/Cart")),
      layout: "BlankLayout",
      meta: {
        publicRoute: true
      }
    },
    {
      path: '/feed',
      component: lazy(() => import('../views/Feed')),
      meta: {
        action: "read",
        resource: "Cliente-Feed"
      }
    },
    {
      path: '/cliente/lives/:id/:cardId?',
      exact: true,
      component: lazy(() => import('../views/Client/Broadcasts')),
      meta: {
        action: "read",
        resource: "Cliente-Transmissões"
      }
    },
    {
      path: "/transmissoes/visualizar",
      component: lazy(() => import("../views/Broadcasts")),
      exact: true,
      meta: {
        action: "read",
        resource: "Transmissões"
      }
    },
    {
      path: "/transmissoes",
      exact: true,
      component: lazy(() => import("../views/Broadcasts/List")),
      meta: {
        action: "read",
        resource: "Transmissões"
      }
    },
    {
      path: "/transmissoes/show/:cardId",
      component: lazy(() => import("../views/Broadcasts")),
      exact: true,
      meta: {
        action: "read",
        resource: "Transmissões"
      }
    },
    {
      path: '/cliente/cursos',
      exact: true,
      component: lazy(() => import('../views/Courses/Dashboard')),
      meta: {
        action: 'read',
        resource: 'Cliente-Meus Cursos'
      }
    },
    {
      path: '/cliente/lives',
      exact: true,
      component: lazy(() => import('../views/Transmissions')),
      meta: {
        action: 'read',
        resource: 'Cliente-Transmissões'
      }
    },
    {
      path: '/recomendacoes',
      exact: true,
      component: lazy(() => import('../views/GroupRecomendation/List')),
      meta: {
        action: 'read',
        resource: 'Recomendações'
      }
    },
    {
      path: '/recomendacoes/cadastro',
      exact: true,
      component: lazy(() => import('../views/GroupRecomendation/Create')),
      meta: {
        action: 'create',
        resource: 'Recomendações'
      }
    },
    {
      path: '/recomendacoes/:id',
      exact: true,
      component: lazy(() => import('../views/GroupRecomendation/Create')),
      meta: {
        action: 'update',
        resource: 'Recomendações'
      }
    },
    {
      path: "/cliente/recomendacoes",
      exact: true,
      component: lazy(() => import("../views/RecommendationsList")),
      meta: {
        action: 'read',
        resource: 'Cliente-Recomendações'
      }
    },
    {
      path: '/recomendacao/cadastro',
      exact: true,
      component: lazy(() => import('../views/GroupRecomendation/Create')),
      meta: {
        action: 'create',
        resource: 'Recomendações'
      }
    },
    {
      path: "/status-das-recomendacoes",
      exact: true,
      component: lazy(() => import("../views/Recomendations")),
      meta: {
        action: 'read',
        resource: 'Status das Recomendações'
      }
    },
    {
      path: "/farol",
      exact: true,
      component: lazy(() => import("../views/Lighthouse/List")),
      meta: {
        action: "read",
        resource: "Farol"
      }
    },
    {
      path: "/farol",
      exact: true,
      component: lazy(() => import("../views/Lighthouse/List")),
      meta: {
        action: "read",
        resource: "Farol"
      }
    },
    {
      path: "/transmissoes/cadastro",
      exact: true,
      component: lazy(() => import("../views/Broadcasts/Create")),
      meta: {
        action: 'create',
        resource: 'Transmissões'
      }
    },
    {
      path: "/transmissoes/editar/:id",
      exact: true,
      component: lazy(() => import("../views/Broadcasts/Create")),
      meta: {
        action: 'update',
        resource: 'Transmissões'
      }
    },
    {
      path: "/grade-programacao",
      exact: true,
      component: lazy(() => import("../views/ScheduleGrid/List")),
      meta: {
        action: "read",
        resource: "Grade de Programação"
      }
    },
    {
      path: '/playlist',
      exact: true,
      component: lazy(() => import('../views/Programs/List')),
      meta: {
        action: "read",
        resource: "Playlist"
      }
    },
    {
      path: '/playlist/cadastro',
      exact: true,
      component: lazy(() => import('../views/Programs/Create')),
      meta: {
        action: "create",
        resource: "Playlist"
      }
    },
    {
      path: '/playlist/:programId',
      exact: true,
      component: lazy(() => import('../views/Programs/Edit')),
      meta: {
        action: "update",
        resource: "Playlist"
      }
    },
    {
      path: "/categorias",
      exact: true,
      component: lazy(() => import("../views/Categories/List")),
      meta: {
        action: "read",
        resource: "Categorias"
      }
    },
    {
      path: "/assinatura/cadastro",
      exact: true,
      component: lazy(() => import("../views/Signature/Create")),
      meta: {
        action: "create",
        resource: "Tipos de Assinatura"
      }
    },
    {
      path: "/assinatura/:id",
      exact: true,
      component: lazy(() => import("../views/Signature/Create")),
      meta: {
        action: "update",
        resource: "Tipos de Assinatura"
      }
    },
    {
      path: "/assinatura",
      exact: true,
      component: lazy(() => import("../views/Signature/List")),
      meta: {
        action: "read",
        resource: "Tipos de Assinatura"
      }
    },
    {
      path: "/metodos",
      exact: true,
      component: lazy(() => import("../views/Methods/List")),
      meta: {
        action: "read",
        resource: "Métodos"
      }
    },
    {
      path: "/email/bind",
      exact: true,
      component: lazy(() => import("../views/EmailBind")),
      meta: {
        action: 'read',
        resource: 'Email binds'
      }
    },
    {
      path: '/flutuante',
      component: lazy(() => import('../views/Floating')),
      layout: "BlankLayout",
      meta: {
        action: "read",
        resource: 'Cliente-Transmissões'
      }
    }
  ],
  changeMenu: [
    {
      id: 'home',
      title: 'Feed',
      icon: <Svg name='menu-home' />,
      navLink: '/feed',
      action: 'read',
      resource: 'Cliente-Feed'
    },
    {
      id: 'broadcasts-client',
      title: 'Transmissões',
      icon: <Svg name="menu-broadcasts" />,
      navLink: '/cliente/lives',
      action: 'read',
      resource: 'Cliente-Transmissões'
    },
    {
      id: 'my-courses',
      title: 'Meus Cursos',
      icon: <Svg name='menu-courses' />,
      navLink: '/cliente/cursos',
      action: 'read',
      resource: 'Cliente-Meus Cursos'
    },
    {
      id: 'my-ebooks',
      title: 'Meus E-books',
      icon: <Svg name='menu-ebooks' />,
      navLink: '/cliente/ebooks',
      action: 'read',
      resource: 'Cliente-Meus E-books'
    },
    {
      id: 'ls-play',
      title: 'LS Play',
      icon: <Svg name='menu-ls-play' />,
      navLink: '/cliente/ls-play',
      action: 'read',
      resource: 'Cliente-LS Play'
    },
    {
      id: 'recomendacoes-cliente',
      title: 'Recomendações',
      icon: <Svg name='menu-recommendations' />,
      navLink: '/cliente/recomendacoes',
      action: 'read',
      resource: 'Cliente-Recomendações'
    }
  ]
}
