import { useEffect, useState } from 'react'

export const Timer = ({ callback = () => {}, timer = 24 * 60 * 60 }) => {
  const initialTime = timer
  const [time, setTime] = useState(initialTime)

  useEffect(() => {
    setInterval(() => {
      setTime((time) => time - 1)
    }, 1000)
  }, [])

  useEffect(() => {
    if (time === 0) {
      callback()
    }
    if (time % 300 === 0 && time !== initialTime) {
      callback()
    }
  }, [time])

  if (time <= 0) {
    return (
      <p>
        <span>00</span>:<span>00</span>:<span>00</span>
      </p>
    )
  }
  return (
    <p>
      <span>{`0${Math.floor((time / (60 * 60)) % 60)}`.slice(-2)}</span>:
      <span>{`0${Math.floor((time / 60) % 60)}`.slice(-2)}</span>:
      <span>{`0${Math.floor(time % 60)}`.slice(-2)}</span>
    </p>
  )
}
