import { useContext, createContext, useState } from 'react'

const IOs16Context = createContext({})

function IOs16Provider({ children }) {
  const [isIOs16, setIsIOs16] = useState(false)

  return (
    <IOs16Context.Provider value={{ isIOs16, setIsIOs16 }}>
      {children}
    </IOs16Context.Provider>
  )
}

function useIOs16() {
  return useContext(IOs16Context)
}

export { IOs16Provider, useIOs16 }
