import {useContext, createContext, useState} from "react"

const RecommendationsContext = createContext({})

function RecommendationsProvider({children}) {
  const [updateRecommendation, setUpdateRecommendation] = useState(false)
  const [chatIsHide, setChatIsHide] = useState(false)

  return (
    <RecommendationsContext.Provider value={{updateRecommendation, setUpdateRecommendation, chatIsHide, setChatIsHide}}>
      {children}
    </RecommendationsContext.Provider>
  )
}

function useRecommendations() {
  return useContext(RecommendationsContext)
}

export {RecommendationsProvider, useRecommendations}
