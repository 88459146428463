const background = require(`@src/assets/images/pages/login/login-background-global.png`).default
const logoWhite = require('@src/assets/images/logo/global-logo.png').default
const logo = require('@src/assets/images/logo/global-logo-black.png').default
const locker = require('@src/assets/images/pages/locker-global.svg').default

export default {
  images: {
    background: {
      src: background
    },
    logo: {
      src: logo,
      alt: 'Liberta Global'
    },
    logoWhite: {
      src: logoWhite,
      alt: 'Liberta Global'
    },
    locker: {
      src: locker,
      alt: 'Locker'
    },
    menuLogo: {
      src: logoWhite,
      alt: 'Liberta Global'
    }
  },
  badgeColor: 'danger',
  bgFilter: 'login-bg-color-filter',
  welcomeMessage: 'WelcomeMessageLibertaGlobal',
  createMessage: 'CreateAccountMessage',
  course1: 'CourseAd 1',
  course2: 'CourseAd 2',
  course3: 'CourseAd 3',
  course4: 'CourseAd 4',
  cssChanges: [{property: '--aux-1', value: '--aux-1-global'}]
}
