import { isValid, format } from "date-fns"
import { cloneDeep } from "lodash"

import Cookies from 'universal-cookie'
import customer from "../services/customer"
import select from "../services/select"
import user from "../services/user"
import TagManager from "react-gtm-module"
import configLiberta from "../configs/config.liberta"

function chunkArrayInGroups(arr, size) {
  const myArray = []
  for (let i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size))
  }
  return myArray
}

export const isTv = () => {

  let operatingSystem = 'Not known'

  if (window.navigator.userAgent.indexOf('TV') !== -1) {
    operatingSystem = 'TV'
  } else if (window.navigator.userAgent.indexOf('Win') !== -1) {
    operatingSystem = 'Windows OS'
  } else if (window.navigator.userAgent.indexOf('Mac') !== -1) {
    operatingSystem = 'MacOS'
  } else if (window.navigator.userAgent.indexOf('X11') !== -1) {
    operatingSystem = 'UNIX OS'
  } else if (window.navigator.userAgent.indexOf('Linux') !== -1) {
    operatingSystem = 'Linux OS'
  }

  return operatingSystem === 'TV'
}


export const storageItem = (
  type,
  name,
  value,
  useCookies = false,
  expireCookieDate = new Date((new Date()).getTime() + (86400000 * 365)) // 1 ano
) => {
  const cookies = new Cookies()

  let toReturn

  // try {
  //   localStorage.setItem('feature_test', 'yes')
  //   if (localStorage.getItem('feature_test') === 'yes') {
  //     localStorage.removeItem('feature_test')
  //     if (type === 'set') {
  //       localStorage.setItem(name, value)
  //     } else if (type === 'get') {
  //       toReturn = localStorage.getItem(name)
  //     } else if (type === 'remove') {
  //       localStorage.removeItem(name)
  //     }
  //   } else {
  //     if (type === 'set') {
  //       cookies.set(name, value)
  //     } else if (type === 'get') {
  //       toReturn = cookies.get(name)
  //     } else if (type === 'remove') {
  //       cookies.remove(name)
  //     }
  //   }
  // } catch (e) {

    if (isTv() || useCookies) {
      if (type === 'set') {
        cookies.set(name, JSON.stringify(value), {path: '/', expires: expireCookieDate})
      } else if (type === 'get') {
        toReturn = cookies.get(name)
      } else if (type === 'remove') {
        cookies.remove(name, {expires: expireCookieDate})
      }
    } else {
      if (type === 'set') {
        window.localStorage.setItem(name, JSON.stringify(value))
      } else if (type === 'get') {
        if (window.localStorage.getItem(name)) {
          if (typeof window.localStorage.getItem(name) === 'string') {
            try {
              toReturn = JSON.parse(window.localStorage.getItem(name))
            } catch (e) {
              toReturn = (window.localStorage.getItem(name))
            }
          } else {
            toReturn = window.localStorage.getItem(name)
          }
        } else {
          toReturn = window.localStorage.getItem(name)
        }
      } else if (type === 'remove') {
        window.localStorage.removeItem(name)
      }
    }
  // }

  return toReturn
}

export const parseJSON = (stringifiedJSON) => {
  let json = null

  try {
    json = JSON.parse(stringifiedJSON)
  } catch (err) {
    console.error(err)
    return null
  }

  return json
}

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => {
  const formatted = num > 999 ? `${(num / 1000).toFixed(1)}k` : num

  return formatted
}

export function formatNumber(entry = '') {
  let formated
  if (entry.length === 10) {
    formated = `(${entry.substr(0, 2)}) ${entry.substr(2, 4)}-${entry.substr(6, 4)}`
  } else {
    formated = `(${entry.substr(0, 2)}) ${entry.substr(2, 5)}-${entry.substr(7, 4)}`
  }

  return `${formated}`
}

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
  const isLogged = storageItem('get', '@L&S::UserData') && storageItem('get', '@L&S::Session')

  return isLogged
}

export const getUserData = () => {
  let i = 1

  let abillities = []

  while (storageItem('get', `@L&S::UserAbility${i}`)) {
    const storageAbility = storageItem('get', `@L&S::UserAbility${i}`)
    abillities = [...abillities, ...storageAbility]
    i++
  }

  const userData = typeof storageItem('get', '@L&S::UserData') === 'string' ? JSON.parse(storageItem('get', '@L&S::UserData')) : storageItem('get', '@L&S::UserData')

  if (userData) {
    userData.ability = abillities
  }
  const pathname = window.location.pathname
  const isSubject = !!pathname.includes("/lancamento/") && !userData
  const isCartWithOutLogin = !!pathname.includes("/carrinho") && !userData
  const isMore =
  ((!!pathname.includes('/curso/') || !!pathname.includes('/interna/')) &&
    !userData) ||
  (!!pathname.includes('/ebooks/') && !userData)
  const isHome = configLiberta.nonLoggedLinkNavigation.some(
    (item) => !!pathname.includes(item)
  ) && !isUserLoggedIn()

  if (isSubject || isCartWithOutLogin || isMore || isHome) {
    return {
      id: 0,
      name: "Usuário Não Logado",
      nickname: "@nickname",
      profile: {
        permissions: [
          {
            create: 1,
            created_at: '2024-03-13T19:35:01.000000Z',
            delete: 1,
            id: 14356,
            manage: 1,
            menu: 1,
            permission: {
              base_profile_id: 3,
              created_at: '2022-01-13T16:16:26.000000Z',
              has_children: 0,
              id: 1,
              name: 'Cliente-Home',
              order: 0,
              parent_id: null,
              updated_at: '2022-01-13T16:16:26.000000Z'
            },
            permission_id: 1,
            profile_id: 1,
            read: 1,
            update: 1,
            updated_at: '2024-03-13T19:35:01.000000Z'
          }
        ]
      }
    }
  }

  return userData
}

export const clearStorageUser = () => {
  let i = 1

  storageItem('remove', '@L&S::UserData')
  storageItem('remove', '@L&S::Session')

  while (storageItem('get', `@L&S::UserAbility${i}`)) {
    storageItem('remove', `@L&S::UserAbility${i}`)
    i++
  }
}
export const orderComments = (data, isHistory = false) => {
  return data.sort((a, b) => {
    let dateA = new Date(
      `${a.date}T${a.hour}`
    )
    let dateB = new Date(
      `${b.date}T${b.hour}`
    )
    if (!isHistory) {
      dateA = new Date(
        `${a.date.split('/').reverse().join('-')}T${a.hour}:00`
      )
      dateB = new Date(
        `${b.date.split('/').reverse().join('-')}T${b.hour}:00`
      )
    }


    if (dateA < dateB) {
      return -1
    } else if (dateA > dateB) {
      return 1
    } else {
      return 0
    }
  })
}

export const getUserSession = () => storageItem('get', '@L&S::Session')

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const getLayoutFromLocalStorage = (layoutKey) => {
  const layout = storageItem('get', layoutKey)

  return parseJSON(layout)
}

export const saveLayoutToLocalStorage = (layoutKey, layout) => {
  return storageItem('set', layoutKey, JSON.stringify(layout))
}

export const removeLayoutFromLocalStorage = (layoutKey) => {
  return storageItem('remove', layoutKey)
}

export const formatToCurrency = (value, locale = 'pt-BR', currency = 'BRL') => {
  const formatting = { style: 'currency', currency }

  return new Intl.NumberFormat(locale, formatting).format(value)
}

export const formatToFloat = (value, locale = 'pt-BR', currency = 'BRL') => {
  const formatting = { style: 'currency', currency }

  return new Intl.NumberFormat(locale, formatting).format(value)
}

export const formatToLocaleString = (value, locale = 'pt-BR') => {
  const formatting = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

  return new Intl.NumberFormat(locale, formatting).format(value)
}

export const gerenateRandomColor = () => {
  const arrayColors = [
    'light-info',
    'light-warning',
    'light-success',
    'light-primary',
    'light-danger'
  ]

  const randomColor = Math.floor(Math.random() * arrayColors.length)

  return randomColor
}

export function convertObjectToFormData(object) {
  return Object.entries(object).reduce(
    (key, value) => (key.append(...value), key),
    new FormData()
  )
}

/**
 * Get a date in format `yyyy-mm-dd 00:00:00`
 * from a date in format `dd/mm/yyyy`
 *
 * @param {string} frontendDate - dd/mm/yyyyy
 */
export function getBackendDate(frontendDate, time = true) {
  if (!frontendDate || typeof frontendDate !== 'string') {
    console.error('You need to provide a valid date string format')
    return ''
  }

  const dataArr = frontendDate.split('/')
  if (time) {
    return `${dataArr[2]}-${dataArr[1]}-${dataArr[0]} 00:00:00`
  }
  return `${dataArr[2]}-${dataArr[1]}-${dataArr[0]}`
}

export function formatStringDate(dateString) {
  const pattern = /(\d{4})\-(\d{2})\-(\d{2})/
  if (!dateString || !dateString.match(pattern)) {
    return null
  }

  return dateString.replace(pattern, '$3/$2/$1')
}

export function formatCpf(cpf) {
  if (!cpf) {
    return null
  }

  cpf = cpf.replace(/[^\d]/g, '')

  if (cpf.length > 11) {
    return cpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  } else {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }
}
export function formatFloat(number) {
  return (new Intl.NumberFormat('', { maximumSignificantDigits: 2 }).format(number))
}

export function maxLengthCheck(object) {

  const maxLength = 4
  if (object) {
    if (object.value?.length > 4) {
      object.value = object.value.slice(0, maxLength)
    }
  }
}

export function objectMap(object, mapFn) {
  return Object.keys(object).reduce(function (result, key) {
    result[key] = mapFn(object[key])
    return result
  }, {})
}

/**
 *
 * @param {string} url
 * @returns
 */
export async function getFileFromURL(url) {
  if (typeof url !== 'string') {
    console.error('You must provide a string')
    return
  }

  const file = await fetch(url)
    .then(r => r.blob())
    .then(blobFile => (
      new File([blobFile], "url", {
        type: `
        image/${url?.split('.')[url?.split('.').length - 1]}
      `
      }
      )))

  return file
}

export const getVideoId = (url = '') => {
  const results = /^.*((vimeo.com\/|youtube.com\/|youtu\.be\/)|(embed\/|v\/|watch\?))v?=?([^#&?]*)/

  return url.match(results) && url.match(results)[4]
}

export const getVideoSource = (url = '') => {
  const regex = new RegExp('vimeo')

  return regex.test(url) ? 'vimeo' : 'youtube'
}

/**
 * Format a given date value into the target format.
 *
 * @param {string|Date} date
 * @param {string=} targetFormat
 * @returns
 */
export const parseDate = (date, targetFormat = 'dd/MM/yyyy') => {
  if (!date) {
    console.error('You must provide a date value.')
    return
  }

  if (date?.length <= 10) {
    date = date?.replace("-", "/").replace("-", "/").replace("-", "/")
  }
  if (typeof date === "string") {
    date = date?.replace("Z", "")
  }

  if (isValid(date)) {
    return format(date, targetFormat)
  }

  if (isValid(new Date(date))) {
    return format(new Date(date), targetFormat)
  }

  console.error('Invalid date value')
}

export const getFileType = (name = '') => {
  if (name?.includes(".jpg")) {
    return "image/jpg"
  }
  if (name?.includes(".png")) {
    return "image/png"
  }
  if (name?.includes(".jpeg")) {
    return "image/jpeg"
  }
  if (name?.includes(".pdf")) {
    return "pdf"
  }
  return "pdf"
}

export const saveLocalStorageUserData = (data, accessToken) => {
  storageItem('set', '@L&S::TrialDays', JSON.stringify(data.trial_days))
  const responseAbility = data?.user?.profile?.permissions?.map(permission => {
    const action = []

    if (permission?.create === 1) {
      action.push('create')
    }

    if (permission?.read === 1) {
      action.push('read')
    }

    if (permission?.update === 1) {
      action.push('update')
    }

    if (permission?.delete === 1) {
      action.push('delete')
    }

    return {
      action,
      subject: permission.permission?.name
    }
  })
  const methods = []

  for (const method of data.method_user_product_id) {
    if (method.trade_methods.length > 0) {
      for (const methodId of method.trade_methods) {
        methods.push(methodId)
      }
    }
  }

  const formattedUserData = {
    ...data?.user,
    methods,
    ability: responseAbility
  }

  const chunckedAbility = chunkArrayInGroups(responseAbility, 20)

  let i = 1

  for (const chunck of chunckedAbility) {
    storageItem('set', `@L&S::UserAbility${i}`, chunck)
    i++
  }

  const userToStorage = cloneDeep(formattedUserData)

  delete userToStorage.ability
  delete userToStorage.profile.permissions

  storageItem('set', '@L&S::UserData', userToStorage)
  if (accessToken) {
    storageItem('set', '@L&S::Session', data?.access_token)
  }

  return responseAbility
}

//Valida se o draft editor esta vazio

export const isEmptyDraftEditor = (e) => {
  return !(e === "<p></p>" || e === "<p></p>\n" || e === "" || e === null)
}


export const formatToIndice = (value) => {
  if (!value || value === '' || value === 0) {
    return '-'
  }

  const nStr = String(parseInt(value))

  const x = nStr.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? `.${x[1]}` : ''
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, `$1.$2`) // changed comma to dot here
  }
  return x1 + x2
}

export const returnOrigin = () => {
  if (window.location.origin === process.env.REACT_APP_GLOBAL_URL) {
    return `global`
  } else if (window.location.origin === process.env.REACT_APP_LIBERTA_URL) {
    return `liberta`
  } else {
    return `ls`
  }
}

export const saveUtmOnCookies = (
  source,
  medium,
  campaign,
  content = null,
  term = null,
  influenciador = null,
  objetivo = null
) => {
  if (!source || !medium || !campaign) {
    return
  }

  const cookies = new Cookies()

  cookies.set('utm_source', source, { path: '/', expires: 0 })
  cookies.set('utm_medium', medium, { path: '/', expires: 0 })
  cookies.set('utm_campaign', campaign, { path: '/', expires: 0 })
  if (content) {
    cookies.set('utm_content', content, { path: '/', expires: 0 })
  }
  if (term) {
    cookies.set('utm_term', term, { path: '/', expires: 0 })
  }
  if (influenciador) {
    cookies.set('utm_influenciador', influenciador, { path: '/', expires: 0 })
  }
  if (objetivo) {
    cookies.set('utm_objetivo', objetivo, { path: '/', expires: 0 })
  }
}

// Magic function, we dont know how it works, but works
// Bruno has 10%

// Functions to automatically calculate height off chat messages
const returnHeight = (row, width, realWidth, isReply, isAdmin) => {
  // +18 because of origin icon, witch one is always shown
  let headerWidth = width + 18
  if (!isReply) {
    headerWidth -= 20
  }
  const headerHeight = 20

  let height = 0
  const brLine = row?.message?.split(/\n|<br>|<br\/>/) || []

  const fonts = [
    {width: 300, large: 10, fixedHeight: 15},
    {width: 345, large: 8.5, fixedHeight: 10},
    {width: 440, large: 9, fixedHeight: 10},
    {width: 458, large: 10, fixedHeight: 10},
    {width: 540, large: 9, fixedHeight: 0},
    {width: 711, large: 7, fixedHeight: 0},
    {width: 767, large: 8, fixedHeight: 0},
    {width: 960, large: 9, fixedHeight: 0},
    {width: 1260, large: 10, fixedHeight: 0},
    {width: 10000, large: 12, fixedHeight: 0}
  ]

  const getLarge = fonts.find(item => realWidth <= item.width) || fonts[fonts.length - 1]

  // const fontLarg = getLarge.large

  if (row.type === 3 || row.type === 5) {
    width -= 34
  }

  if (row.type === 1) {
    height += 5.5
  }

  // const caracInOneline = width / fontLarg

  let i = 0

  for (const br of brLine) {
    let count = 0

    const stripped = br.replace(/<[^>]+>/g, '<')
    const words = stripped.split(' ')
    let thisLines = 1
    for (const word of words) {
      const wordWidth = (word.length * 8) + 7
      count += wordWidth

      const calc = Math.ceil(wordWidth / width)

      if (calc > 1) {
        thisLines += calc
        count = 0
      } else {
        if (count >= width) {
          count = wordWidth
          thisLines++
        }
      }
    }
    i += thisLines
    // i += Math.round((br.replace(/<[^>]+>/g, '').length / caracInOneline)) === 0 ? 1 : Math.round((br.replace(/<[^>]+>/g, '').length / caracInOneline))
  }

  let lines = Math.ceil(i)

  if (row?.message?.length === 0) {
    lines = 0
  }

  if (row?.user?.admin) {
    headerWidth -= 18
  }

  if (row?.user?.senior !== 0) {
    headerWidth -= 18
  }

  if (!isReply && isAdmin) {
    if (row?.user?.premium) {
      headerWidth -= 18
    }
    if (row?.user?.trial) {
      headerWidth -= 18
    }
    if (row?.user?.courtesy) {
      headerWidth -= 18
    }
  }

  const userInfo = `${row?.user?.nickname ? row?.user?.nickname : row?.user?.name}${row?.user?.identifier ? ` - @${row?.user?.identifier}` : ''}`

  const userInfoWords = userInfo.split(' ')

  let j = 1
  let count = 0

  for (const userInfoWord of userInfoWords) {
    const userInfoWordWidth = (userInfoWord.length * 9) + 7
    count += userInfoWordWidth

    const calc = Math.ceil(userInfoWordWidth / headerWidth)

    if (calc > 1) {
      j += calc
      count = 0
    } else {
      if (count >= headerWidth) {
        count = userInfoWordWidth
        j++
      }
    }
  }

  if (j > 1) {
    // headerHeight = j * 15
  }

  height += (lines * 20) + 68 + headerHeight
  if (row.files_message?.length > 0) {
    height += 148
  }

  if (lines === 1) {
    height += getLarge.fixedHeight
  }

  return height
}

export const RowHeight = (indexObj, width, messages, pagination, moreRef, isAdmin, silencedUsers = [], nextMoreRef, loadingCustomPage) => {
  const index = indexObj.index
  const row = messages[index]

  if (!!row?.user_chat?.id) {
    row.user = row.user_chat
  }

  const hidden = silencedUsers?.find((user) => {
    return user?.id === row?.user?.id && (new Date(user?.muted_at) < new Date(row?.created_at))
  })

  if ((row.hidden && (((getUserData()?.id !== row.user_id && row.origin === 2) || row.origin === 1) && getUserData()?.profile_id === 3)) || row.deleted_at || hidden) {
    if (index === 0) {
      return moreRef?.current?.offsetHeight || 58
    }
    return 0
  }

  let height = returnHeight(row, Math.ceil(width - (114 + 15)), width, false, isAdmin)

  if (index === 0) {
    if (pagination.to !== pagination.total) {
      height += moreRef?.current?.offsetHeight || 58
    } else {
      height += 15
    }
  }

  if (index === messages.length - 1 && loadingCustomPage) {
    height += nextMoreRef?.current?.offsetHeight || 58
  }

  if (row.reply_to && row.reply_to?.message) {
    height += returnHeight(row.reply_to?.message, Math.ceil(width - 211), width, true, isAdmin)
  }

  return height
}


const checkCep = async (value) => {
  const cep = value.replace(/[^0-9]/g, '')
  if (cep.length === 8) {
    const response = await customer.getAddressByCep(cep)
    const values = response.data
    const citiesResponse = await select.cityId(values.uf_id)
    return {
      address: values.logradouro,
      district: values.bairro,
      country: 'Brasil',
      uf_id: values.uf_id,
      city_id: values.city_id,
      cityList: citiesResponse?.status === 200 ? citiesResponse.data : []
    }
  }
}
export const getDataUserAddress = async (userId) => {
  const responseUserId = await user.selectAddresses({ id: userId })
  let address = null,
    zip_code = null,
    complement = null,
    district = null,
    city_id = null,
    country = null,
    number = null,
    customerInfo = null,
    user_address_id = null,
    id_city_list = null,
    uf_id = null,
    uf_name = null,
    city_name = null

  if (responseUserId.data?.length > 0) {
    const [addrs] = responseUserId.data
    const responseSelectAddress = await user.selectAddress(addrs.id)
    user_address_id = addrs?.id
    if (responseSelectAddress.status === 200) {
      const { data } = responseSelectAddress
      address = data.address
      zip_code = data.zip_code
      complement = data.complement
      district = data.district
      country = data.country
      number = data.number
      city_id = data.city_id
      uf_name = data.name_uf
      city_name = data.name_city
      customerInfo = { city_id }

      if (zip_code) {
        const dataCEP = await checkCep(zip_code)
        id_city_list =
          dataCEP?.cityList?.length > 0 ? dataCEP?.cityList[0].id : null
        address = dataCEP.address
        district = dataCEP.district
        country = dataCEP.country
        uf_id = dataCEP.uf_id
        city_id = dataCEP.city_id
      }


      return {
        customerInfo,
        user_address_id,
        address,
        zip_code,
        complement,
        district,
        country,
        number,
        id_city_list,
        uf_id,
        uf_name,
        city_name
      }
    } else {
      return {
        customerInfo,
        user_address_id,
        address,
        zip_code,
        complement,
        district,
        country,
        number,
        id_city_list,
        uf_id,
        uf_name,
        city_name
      }
    }
  } else {
    return {
      customerInfo,
      user_address_id,
      address,
      zip_code,
      complement,
      district,
      country,
      number,
      id_city_list,
      uf_id,
      uf_name,
      city_name
    }
  }
}

export const setDataLayer = (event, info) => {
  const tagManagerArgs = {
    dataLayer: {
      event,
      ...info
    }
  }

  TagManager.dataLayer(tagManagerArgs)
}

export const getNow = () => {
  const day = new Date().getDate()
  const month = new Date().getMonth() + 1
  const year = new Date().getFullYear()

  const hour = new Date().getHours()
  const minute = new Date().getMinutes()

  return {
    date: `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${year}`,
    hour: `${hour}:${minute}`
  }
}