import {useContext, createContext, useState, useEffect} from "react"
import auth from "../../services/auth"
import {saveLocalStorageUserData, storageItem, getUserSession} from "@utils"
import { BroadcastsContext } from '@src/utility/context/Broadcasts'
import { useConfigs } from '@src/utility/hooks/useConfigs'
import { AbilityContext } from '@src/utility/context/Can'
import {toast} from "react-toastify"
import { useHistory } from "react-router-dom"
import cartApi from "@src/services/cart"

const ProfileContext = createContext({})

function ProfileProvider({children}) {
  const [url, setUrl] = useState("")
  const [changeOnTrail, setChangeOnTrail] = useState(false)
  const [updateProfile, setUpdateProfile] = useState(false)
  const [changeInfo, setChangeInfo] = useState(false)
  const [autoOpenTrial, setAutoOpenTrial] = useState(false)
  const [hubPostId, setHubPostId] = useState()
  const hubInfo = JSON.parse(storageItem('get', '@L&S::UserHubInfo'))
  const [menuShowing, setMenuShowing] = useState((!!hubInfo?.showingMenu) ? hubInfo.showingMenu : 'client')
  const history = useHistory()
  //const [broadcastDelay, setBroadcastDelay] = useState(0)
  const { setBroadcasts, setBroadcastDelay, setSignatures } = useContext(BroadcastsContext)
  const { cmsOrigin } = useConfigs()
  const ability = useContext(AbilityContext)

  const [zoom, setZoom] = useState(storageItem('get', '@L&S::LsZoom') ?? '0.9')

  const [cartQuantity, setCartQuantity] = useState(0)

  const handleChangeZoom = (value) => {
    setZoom(value)
    storageItem('set', '@L&S::LsZoom', value)
  }

  useEffect(() => {
    document.body.style.zoom = zoom
  }, [zoom])

  const calcCartQuantity = (products) => {
    let total = 0

    for (const product of products) {
      total += product.amount
    }

    setCartQuantity(total)
  }

  const getCart = async () => {
    if (window.location.pathname === '/login') {
      return
    }

    if (cmsOrigin !== 'liberta') {
      return
    }

    const token = getUserSession()

    const request = await cartApi.customerCarts(token)

    if (request.isAxiosError) {
      return setCartQuantity(0)
    }

    const {data: cartData} = request

    let calc = 0

    for (const tempCart of cartData) {
      calc += tempCart.products_amount
    }

    setCartQuantity(calc)
  }

  const updateUserData = async (data) => {
    if (data) {
      setBroadcastDelay(data.delay_broadcast)
      setBroadcasts(data?.broadcasts)
      setSignatures(data?.product_signatures)
      storageItem('set', '@L&S::ShowTrial', JSON.parse(data.show_trial))
      storageItem('set', '@L&S::ChatAnalystMessageSize', data?.chat_config?.analyst_message_size || 100)
      storageItem('set', '@L&S::ChatClientMessageSize', data?.chat_config?.client_message_size || 20)

      const responseAbility = saveLocalStorageUserData(data, data?.access_token)

      ability.update(responseAbility)

      if (data?.first_access === 1) {
        setAutoOpenTrial(true)
      }
      return
    }

    if (window.location.pathname === '/login') {
      return
    }

    const response = await auth.get({force_logout: 0})

    //setIsLoading(false)

    if (response.status === 200) {
      const { data } = response
      setBroadcastDelay(data.delay_broadcast)
      setBroadcasts(data?.broadcasts)
      setSignatures(data?.product_signatures)
      storageItem('set', '@L&S::ShowTrial', JSON.parse(data.show_trial))
      storageItem('set', '@L&S::ChatAnalystMessageSize', data?.chat_config?.analyst_message_size || 100)
      storageItem('set', '@L&S::ChatClientMessageSize', data?.chat_config?.client_message_size || 20)

      const responseAbility = saveLocalStorageUserData(data, data?.access_token)

      ability.update(responseAbility)

      if (data?.first_access === 1) {
        setAutoOpenTrial(true)
      }
      if (url === "") {
        return
      }
      return history.push(`/${url}`)
    }

    if (response.status === 500) {
      return toast.error('Erro na comunicação com o servidor. Contate o suporte.')
    }

    const { response: responseData } = response

    if (responseData?.status === 400) {

      if (responseData?.data instanceof Object) {
        return toast.error(Object.values(responseData.data)[0])
      }

      if (!responseData?.data?.error) {
        return toast.error('Houve um erro ao concluir esta ação.')
      }

      if (typeof responseData?.data.error === 'string') {
        return toast.error(responseData.data.error)
      }

      if (responseData?.data?.error instanceof Array) {
        return toast.error(responseData.data.error[0])
      }

      if (responseData?.data?.error instanceof Object) {
        return toast.error(Object.values(responseData.data.error).flat(Infinity))
      }
    }
  }

  useEffect(() => {
    updateUserData()
    getCart()
  }, [])

  return (
    <ProfileContext.Provider value={{url, setUrl, changeInfo, setChangeInfo, autoOpenTrial, setAutoOpenTrial, cartQuantity, calcCartQuantity, updateUserData, updateProfile, setUpdateProfile, setBroadcastDelay, setBroadcasts, setSignatures, changeOnTrail, setChangeOnTrail, setCartQuantity, menuShowing, setMenuShowing, hubPostId, setHubPostId, zoom, handleChangeZoom}}>
      {children}
    </ProfileContext.Provider>
  )
}

function useProfile() {
  return useContext(ProfileContext)
}

export {ProfileProvider, useProfile}
