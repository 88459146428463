import { useEffect, useState } from 'react'
import { Button, CardBody, Col, Form, Modal, Row, Spinner } from 'reactstrap'
import { Clock } from 'react-feather'

import styles from './styles.module.scss'

import { toast } from 'react-toastify'

import { Svg } from '../Svg'
import { Input } from '../Input'
import { Timer } from '../../views/Cart/components/Timer'
import { useModalPix } from '../../utility/hooks/useModalPix'

export const ModalPix = ({ isOpen }) => {
  const { data, handleCloseModalPix } = useModalPix()
  const [minutosFaltantes, setMinutosFaltantes] = useState(0)

  const [loading] = useState()

  const [pixCode, setPixCode] = useState()
  const [pixUrl, setPixUrl] = useState(null)
  useEffect(() => {
    if (data) {
      setPixCode(data.order_payments[0]?.pix_qrcode)
      setPixUrl(data.order_payments[0].pix_qrcode_url)
      const dataAtual = new Date()
      const dataEspecifica = new Date(
        data.order_payments[0].pix_expiration_date
      )
      const diferencaEmMilissegundos = dataEspecifica - dataAtual
      const diferencaEmSegundos = Math.floor(diferencaEmMilissegundos / 1000)

      setMinutosFaltantes(diferencaEmSegundos)
    }
  }, [data, isOpen])

  const handleCopy = () => {
    navigator.clipboard.writeText(pixCode)
    toast.success('Código foi copiado para área de transferência')
  }

  return (
    <Modal isOpen={isOpen} toggle={handleCloseModalPix}>
      {loading ? (
        <CardBody className={styles.spinnerWrapper}>
          <Spinner
            color="primary-default"
            style={{ width: '3rem', height: '3rem' }}
          />
        </CardBody>
      ) : (
        <Row className={styles.mediaController}>
          <Col>
            <Col className={styles.w_background}>
              <Col style={{ padding: 0 }}>
                <p className={styles.addressTitle}>Opções de pagamento</p>
              </Col>

              <Form style={{ marginTop: 20 }}>
                <>
                  <Col
                    style={{ marginTop: 20 }}
                    className={styles.pixContainer}
                  >
                    <h2>Escaneie esse código para pagar</h2>
                    <Row>
                      <Col className={styles.infoArea}>
                        <p>
                          <span>1. </span>Acesse seu Internet Banking ou app de
                          pagamentos.
                        </p>
                        <p>
                          <span>2. </span>Escolha pagar via Pix.
                        </p>
                        <p>
                          <span>3. </span>Escaneie o seguinte código:
                        </p>
                      </Col>
                      <Col className={styles.qrcodeArea}>
                        <div className={styles.qrCode}>
                          {/*<QRCodeSVG value={pixCode}/>*/}
                          {pixUrl ? (
                            <img src={pixUrl} alt="Pagamento via pix" />
                          ) : (
                            <></>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <p className={styles.paymentTime}>
                      <Clock size={18} />
                      Seu pagamento será aprovado em alguns segundos
                    </p>
                  </Col>
                  <Col
                    style={{ marginTop: 20 }}
                    className={`${styles.pixContainer} ${styles.borderTop}`}
                  >
                    <h2>Ou copie este código QR para fazer o pagamento</h2>
                    <p>
                      Escolha pagar via Pix pelo seu Internet Banking ou app de
                      pagamentos. Depois, copie e cole o seguinte código:
                    </p>
                    <div className={styles.pixCodeArea}>
                      <Row xs={12} className={styles.pixCodeButtonsArea}>
                        <Col className={styles.pixCodeInput}>
                          <Input
                            disabled={true}
                            value={pixCode}
                            defaultValue={pixCode}
                          />
                        </Col>
                        <Col className={styles.pixCodeButton}>
                          <Button color="primary-default" onClick={handleCopy}>
                            Copiar código <Svg name={'copy'} />
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <p style={{ marginBottom: '30px' }}>
                      A liberação do produto ocorrerá apenas após a confirmação
                      de pagamento.
                    </p>
                    <div className={styles.timerArea}>
                      <div>
                        <Svg name={'pix_clock'} />
                        {minutosFaltantes > 0 && (
                          <Timer timer={minutosFaltantes} />
                        )}
                      </div>
                      <p>Aguardando confirmação do PIX...</p>
                    </div>
                  </Col>
                </>
              </Form>
            </Col>
          </Col>
        </Row>
      )}
    </Modal>
  )
}
