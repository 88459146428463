import React, { useMemo } from 'react'

import {
  FormGroup,
  Label,
  Input as InputComponent,
  FormText
} from 'reactstrap'
import { useController } from "react-hook-form"

import InputPasswordToggle from '@components/input-password-toggle'
import Cleave from 'cleave.js/react'
import classNames from 'classnames'

export const Input = ({
  control,
  name = "",
  defaultValue = "",
  label,
  rules = {},
  type = 'text',
  options,
  placeholder,
  onSelect = () => { },
  helperText = '',
  ...props
}) => {

  const { field: { ref, ...inputProps }, fieldState: { error, invalid } } = useController({
    name,
    control,
    rules,
    defaultValue
  })

  const handleChange = (event) => {
    if (type === 'slug') {
      const newValue = event?.target?.value.replace(/ /g, '')
      inputProps.onChange(newValue)
      return newValue
    } else {
      if (typeof props?.onChange === "function") {
        props?.onChange(event)
      }
      inputProps.onChange(event)
    }
  }

  const cleaveClassNames = classNames({
    'form-control': true,
    'is-invalid': invalid
  })

  const cleaveTimeOptions = useMemo(() => ({
    time: true,
    timePattern: ['h', 'm']
  }), [])

  return (
    <FormGroup>
      <Label>{label}</Label>
      {!!options ? (
        <InputComponent
          id={name}
          type={type}
          {...inputProps}
          {...props}
          invalid={invalid}
          innerRef={ref}
          onChange={e => {
            inputProps.onChange(e?.target?.value)
            onSelect(e?.target?.value)
          }}
        >
          <option hidden>{placeholder || "Selecione uma opção"}</option>
          {options?.map(option => (
            <option key={option?.id} value={option?.id}>{option?.name}</option>
          ))}
        </InputComponent>
      ) : (
        <>
          {type === 'password' ? (
            <InputPasswordToggle
              className='input-group-merge'
              {...inputProps}
              invalid={invalid}
              innerRef={ref}
              placeholder={placeholder || ""}
              {...props}
            />
          ) : type === 'cleave' ? (
            <Cleave
              options={cleaveTimeOptions}
              className={cleaveClassNames}
              {...props}
              {...inputProps}
            />
          ) : (
            <InputComponent
              type={type}
              {...inputProps}
              invalid={invalid}
              innerRef={ref}
              placeholder={placeholder || ""}
              {...props}
              onChange={handleChange}
            />
          )}
        </>
      )}
      {error && <p style={{ color: '#ea5455', fontSize: '12px' }}>{error.message}</p>}
      {helperText && (
        <FormText>{helperText}</FormText>
      )}
    </FormGroup>
  )
}
